import * as Sentry from "@sentry/react";
import { useCallback, type ReactNode } from "react";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";

interface ErrorBoundaryProps {
    children: ReactNode;
    fallback?: React.ReactElement | Sentry.FallbackRender | undefined;
    // Use this to reset the state of the parent component otherwise it will reload the page
    onReset?: () => void;
}
export function ErrorBoundary({ children, fallback, onReset }: ErrorBoundaryProps) {
    const handleReset = useCallback(() => {
        if (onReset) {
            onReset();
        } else {
            window.location.reload();
        }
    }, [onReset]);

    return (
        <Sentry.ErrorBoundary
            fallback={fallback || GenericCardError}
            onReset={handleReset}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
}

interface GenericCardErrorProps {
    error: Error;
    componentStack: string;
    eventId: string;
    resetError: () => void;
}
function GenericCardError({ resetError }: GenericCardErrorProps) {
    return (
        <div className="lui-flex lui-gap-1 lui-flex-col lui-rounded-lg lui-border-l-4  lui-p-4 lui-text-center lui-justify-center lui-h-full">
            <div className="lui-text-center lui-mb-2">
                <Button variant="base" href="/">
                    <Icon name="LandInsights" color="gray-500" size="lg" />
                </Button>
            </div>

            <Typography
                color="gray-900"
                weight="bold"
                className="lui-flex lui-flex-col"
            >
                Something went wrong!
            </Typography>
            <Typography color="gray-900" className="lui-flex lui-flex-col">
                <span>Our team has been notified and will work on a fix soon</span>

                <span className="lui-flex lui-justify-center">
                    <Button
                        className="lui-mt-2"
                        variant="base"
                        icon="Refresh"
                        onClick={resetError}
                    >
                        Reset
                    </Button>
                </span>
            </Typography>
        </div>
    );
}
