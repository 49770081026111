import {
    fetchMapboxFeature,
    type MapboxSuggestion,
} from "@src/functions/mapbox_search";
import { Autocomplete, useAutocomplete } from "@src/land_ui/autocomplete/autocomplete";
import { Dropdown, type DropdownOption } from "@src/land_ui/dropdown/dropdown";
import { Icon } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";
import {
    type OwnerSearchResponse,
    type PaginatedFullParcelList,
    type ParcelSearchResponse,
    type ParcelsSearchRetrieveParams,
} from "@src/orval/gen/model";
import {
    formatCoordinates,
    parseCoordinates,
    searchMapbox,
} from "@src/pages/parcel_viewer/controls/search";
import {
    type County,
    type ParcelOption,
    type Point,
} from "@src/pages/parcel_viewer/types";
import clsx from "clsx";
import { fetch } from "functions";
import { type Feature } from "geojson";
import { get } from "lodash";
import { useState } from "react";
import { parcelsSearchRetrieve } from "@src/orval/gen/api";

const SEARCH_LOCATION_TYPE = "Location";
const SEARCH_PARCEL_TYPE = "Parcel #";
const SEARCH_OWNER_TYPE = "Owner";

const QUICK_SEARCH_OPTIONS = [
    { id: SEARCH_LOCATION_TYPE, label: "Location" },
    { id: SEARCH_PARCEL_TYPE, label: "Parcel #" },
    { id: SEARCH_OWNER_TYPE, label: "Owner" },
];

interface QuickSearchType {
    PropertyID?: number;
    feature?: Feature;
    point?: Point;
    ownerResults?: ParcelSearchResponse;
}
interface QuickSearchProps {
    onSearch: (search: QuickSearchType) => void;
    county?: County;
}

export function getPlaceFormattedWithoutCounty(place: string) {
    if (!place) {
        return "";
    }
    const placeFormatted = place?.split(",");
    placeFormatted.pop();
    return placeFormatted.join(",");
}

export function QuickSearch({ onSearch, county }: QuickSearchProps) {
    const [searchTypeOption, setSearchTypeOption] = useState<DropdownOption>(
        QUICK_SEARCH_OPTIONS[0],
    );

    const addressAutocompleteProps = useAutocomplete<
        MapboxSuggestion | { point: Point }
    >({
        id: "address-autocomplete",
        placeholder: "Enter GPS or Address...",
        hideBorder: true,
        autoFocus: true,
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                return null;
            }

            if ("point" in selectedItem.data) {
                onSearch({ point: selectedItem.data.point });
                return;
            }

            const featureCollection = await fetchMapboxFeature(selectedItem.id);
            const feature = featureCollection.features[0];
            onSearch({ feature });
        },
        onSearch: async (inputValue) => {
            const point = parseCoordinates(inputValue);
            if (point) {
                return [
                    {
                        label: inputValue,
                        id: formatCoordinates(point),
                        inputValue,
                        data: { point },
                    },
                ];
            }

            const data = await searchMapbox(inputValue, "district,postcode,address");
            return (
                data?.map((e) => {
                    return {
                        id: e.mapbox_id,
                        label: e.name,
                        inputValue: `${e.name}, ${getPlaceFormattedWithoutCounty(
                            e.place_formatted,
                        )}`,
                        data: e,
                    };
                }) ?? []
            );
        },
        renderLabel: (item) => (
            <>
                <Icon name="Pin" />
                <span className="lui-items-center lui-flex lui-gap-4 lui-truncate">
                    <Typography variant="span" weight="medium">
                        {item.label}
                    </Typography>

                    <Typography variant="span" color="gray-700">
                        {getPlaceFormattedWithoutCounty(
                            get(item.data, "place_formatted"),
                        )}
                    </Typography>
                </span>
            </>
        ),
    });

    const countyAutocompleteProps = useAutocomplete({
        id: "county-search-autocomplete",
        hideBorder: true,
        placeholder: "Enter county name...",
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                return;
            }
            const featureCollection = await fetchMapboxFeature(selectedItem.id);
            const feature = featureCollection.features[0];
            onSearch({ feature });

            // Reset other inputs as we change the county option
            parcelAutocompleteProps.onReset();
            ownerAutocompleteProps.onReset();
        },
        onSearch: async (inputValue) => {
            const data = await searchMapbox(inputValue, "district");
            return (
                data?.map((e) => {
                    const formattedName = `${e.name}, ${e.context.region.region_code}`;
                    return {
                        id: e.mapbox_id,
                        label: formattedName,
                        inputValue: formattedName,
                        data: e,
                    };
                }) ?? []
            );
        },
    });

    const parcelAutocompleteProps = useAutocomplete<ParcelOption>({
        id: "parcel-search-autocomplete",
        hideBorder: true,
        placeholder: "Enter APN...",
        disabled: !county,
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                return;
            }
            const { point, PropertyID } = selectedItem.data;
            onSearch({ point, PropertyID });
        },
        onSearch: async (inputValue) => {
            if (inputValue?.length <= 1) {
                return [];
            }

            const params = {
                county: county.id,
                apn: inputValue,
                page_size: "5",
            };
            const queryString = new URLSearchParams(params).toString();
            const searchResult: PaginatedFullParcelList = await fetch(
                `/api/property/parcels/?${queryString}`,
            );

            return searchResult.results.map(({ point, PropertyID, APN }) => {
                return { id: APN, label: APN, data: { point, PropertyID } };
            });
        },
    });

    const ownerAutocompleteProps = useAutocomplete({
        id: "owner-search-autocomplete",
        placeholder: "Enter owner name...",
        hideBorder: true,
        disabled: !county,
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                return;
            }
            const params: ParcelsSearchRetrieveParams = {
                county: county.id,
                // @ts-ignore
                bbox: 1,
            };
            const owners = selectedItem.id.split(",");
            owners.forEach((owner, i) => {
                // @ts-ignore
                params[`owner${i + 1}_exact`] = owner;
            });
            const searchResult = await parcelsSearchRetrieve(params);
            onSearch({ ownerResults: searchResult });
        },
        onSearch: async (inputValue) => {
            if (inputValue?.length <= 1) {
                return [];
            }

            const params = {
                county: county.id,
                owner_name: inputValue,
            };
            const queryString = new URLSearchParams(params).toString();
            const searchResult: OwnerSearchResponse[] = await fetch(
                `/api/property/parcels/owner_search/?${queryString}`,
            );

            return searchResult.map((e) => {
                return { id: e.value, label: e.label };
            });
        },
    });

    /**
     * This is a workaround to handle focus change when dropdown or an item is selected to switch between inputs
     */
    const focusChange = (callback: () => void) => setTimeout(callback, 10);

    return (
        <div className="lui-flex lui-items-center lui-gap-3 lui-rounded-inherit">
            <div
                className={clsx(
                    "lui-shadow-sm lui-grid lui-grid-cols-5 lui-rounded-3xl",
                    "has-[.lui-autocomplete-address-autocomplete--open]:lui-rounded-br-none",
                    "has-[.lui-autocomplete-parcel-search-autocomplete--open]:lui-rounded-br-none",
                    "has-[.lui-autocomplete-owner-search-autocomplete--open]:lui-rounded-br-none",

                    "lui-w-[650px]",

                    // Remove rounded bottom left corner when dropdown is open
                    "has-[.lui-dropdown-quick-search-type--open]:lui-rounded-bl-none",
                )}
            >
                <div className="lui-col-span-1 lui-rounded-l-inherit">
                    <Dropdown
                        id="quick-search-type"
                        options={QUICK_SEARCH_OPTIONS}
                        onSelect={(selectedItem) => {
                            if (!selectedItem) {
                                return;
                            }
                            setSearchTypeOption(selectedItem);
                            focusChange(() => {
                                if (selectedItem.id === SEARCH_LOCATION_TYPE) {
                                    addressAutocompleteProps.ref.current?.focus();
                                } else if (
                                    selectedItem.id === SEARCH_PARCEL_TYPE ||
                                    selectedItem.id === SEARCH_OWNER_TYPE
                                ) {
                                    countyAutocompleteProps.ref.current?.focus();
                                }
                            });
                        }}
                        selectedItem={searchTypeOption}
                        hideBorder
                        className="lui-border-y-0 lui-border-x-0 lui-border-solid lui-border-r lui-border-gray-200"
                    />
                </div>

                {searchTypeOption.id === SEARCH_LOCATION_TYPE && (
                    <div className="lui-flex-grow lui-col-span-4 lui-rounded-r-inherit">
                        <Autocomplete {...addressAutocompleteProps} />
                    </div>
                )}

                {(searchTypeOption.id === SEARCH_PARCEL_TYPE ||
                    searchTypeOption.id === SEARCH_OWNER_TYPE) && (
                    <>
                        <div className="lui-flex-grow lui-border-y-0 lui-border-x-0 lui-border-solid lui-border-r lui-border-gray-200 lui-col-span-2">
                            <Autocomplete {...countyAutocompleteProps} />
                        </div>
                    </>
                )}

                {searchTypeOption.id === SEARCH_PARCEL_TYPE && (
                    <div className="lui-col-span-2 lui-rounded-r-inherit lui-whitespace-pre">
                        <Autocomplete {...parcelAutocompleteProps} />
                    </div>
                )}

                {searchTypeOption.id === SEARCH_OWNER_TYPE && (
                    <div className="lui-col-span-2 lui-rounded-r-inherit">
                        <Autocomplete {...ownerAutocompleteProps} />
                    </div>
                )}
            </div>
        </div>
    );
}
