import dayjs from "dayjs";
import type { User } from "@src/pages/parcel_viewer/types";

export default function FreeTrialNotification({ user }: { user: User }) {
    return (
        <>
            {user?.stripe_trial_end &&
                dayjs(user.stripe_trial_end).isAfter(dayjs()) && (
                    <div className="lui-w-full lui-h-8 lui-bg-orange-700 lui-text-xs lui-content-center lui-color-black lui-text-center">
                        Your free trial ends{" "}
                        <strong>{dayjs(user.stripe_trial_end).fromNow()}</strong>. You
                        will be charged at the end of the trial unless you cancel
                        beforehand.
                    </div>
                )}
        </>
    );
}
