import { bbox } from "@turf/turf";
import { type ParcelSearchResponse, type ParcelSavedList } from "@src/orval/gen/model";
import { fetch, isLoggedIn, logout } from "functions";
import { type FeatureCollection } from "geojson";
import { isNumber } from "lodash";
import { LngLatBounds } from "mapbox-gl";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Map, {
    Layer,
    type MapboxMap,
    type MapLayerMouseEvent,
    type MapRef,
    Marker,
    Source,
} from "react-map-gl";
import { MAPBOX_TOKEN } from "settings";
import {
    DataLayerControl,
    useDataLayerControl,
} from "@src/components/map_tools/data_layer_control";
import { MapHelpControl } from "@src/components/map_tools/map_help_control";
import {
    GoogleSourceLayer,
    MapLayerControl,
    useMapLayer,
} from "@src/components/map_tools/map_layer_control";
import { MapToolsControl } from "@src/components/map_tools/map_tools_control";
import { ZoomControls } from "@src/components/map_tools/zoom_control";
import { useQueryParam } from "@src/hooks/useQueryParam";
import { Button } from "@src/land_ui/button/button";

import { useRupt } from "@src/hooks/useRupt";
import {
    type OwnerPropertyState,
    type ParcelViewerContextType,
    ParcelViewerContext,
    UserContext,
} from "./context";
import { ParcelDetail } from "./controls/detail";
import InspectControl from "./controls/inspect";
import { FloodZoneHoverTooltip } from "./floodzone_tooltip";
import { MapHeader } from "./map_header";
import CompPopup from "./modals/comp_popup";
import ParcelChooserPopup from "./modals/parcel_chooser_popup";
import {
    buildingsSource,
    compSource,
    countySource,
    floodzoneSource,
    maptilerSource,
    ownerSource,
    parcelSource,
    roadsSource,
    wetlandsSource,
} from "./sources";
import {
    buildingsOutline,
    compsCircleStyle,
    compsLabelStyle,
    countyHighlightOutline,
    countyHighlightShadow,
    countyIdentify,
    countyNameLayer,
    countyOutline,
    floodzone100,
    floodzone500,
    floodzoneCoastal,
    floodzoneFloodway,
    ownerSelectedOutline,
    ownersLabelStyle,
    parcelHighlightOutline,
    parcelHighlightShadow,
    parcelIdentify,
    parcelSelectedOutline,
    roadsOutline,
    searchResultsCluster,
    searchResultsClusterLabel,
    surfaceWaterFill,
    surfaceWaterOutline,
    wetlandsFill,
    wetlandsOutline,
    zipHighlightOutline,
    zipHighlightShadow,
    zipIdentify,
    zipOutline,
} from "./styles";
import {
    type County,
    type DebugPoint,
    type MapFilter,
    type Parcel,
    type PopupInfo,
    type User,
} from "./types";
import { useParcel } from "@src/hooks/useParcel";
import FreeTrialNotification from "@src/components/free_trial";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";
import { ContourLayer } from "./layers/contour_layer";

const initialViewState = {
    longitude: -93.55,
    latitude: 39.6,
    zoom: 4,
};

const IMAGES = ["rounded_green", "rounded_blue", "rounded_red"];

const loadImages = (map: MapboxMap, images: string[]) => {
    for (const image of images) {
        map.loadImage(`/static/images/${image}.png`, (error: any, imageData: any) => {
            if (error) {
                console.log(error);
                return;
            }
            if (!map.hasImage(image)) {
                map.addImage(image, imageData, {
                    stretchX: [[10, 54]],
                    stretchY: [[10, 54]],
                    content: [7, 7, 57, 57],
                    pixelRatio: 1,
                });
            }
        });
    }
};

// TODO(KM): Move to the type definition file
declare global {
    interface Window {
        Intercom: (action: string, options: any) => void;
    }
}

export default function ParcelViewer() {
    const [user, setUser] = useState<User>();
    const { attachedRupt } = useRupt();
    useEffect(() => {
        if (!isLoggedIn()) {
            logout();
            return;
        }

        const fetchUser = async () => {
            try {
                const newUser = await fetch("/user/user/");
                setUser(newUser);
                attachedRupt(newUser);

                // hides the Intercom chat widget at the bottom right of the screen
                window.Intercom("update", {
                    hide_default_launcher: true,
                });
            } catch (xhr) {
                // TODO: show error modal
                console.error("Error loading user", xhr);
            }
        };

        fetchUser();
    }, [attachedRupt, setUser]);

    const context = { user };

    return (
        <UserContext.Provider value={context}>
            <ErrorBoundary
                onReset={() => {
                    window.location.reload();
                }}
            >
                <SubscriptionRequiredModal user={user} />
                <ParcelViewerMap />
            </ErrorBoundary>
        </UserContext.Provider>
    );
}

function SubscriptionRequiredModal({ user }: { user: User }) {
    return (
        <>
            {user && !user.has_paid_subscription && (
                <Modal
                    show
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Subscription Required
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <p>There's a problem with your subscription.</p>
                        <p>
                            If you canceled and are looking to resubscribe, contact{" "}
                            <a href="mailto:hello@landinsights.co">
                                hello@landinsights.co
                            </a>
                        </p>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

function ParcelViewerMap() {
    const mapRef = useRef<MapRef>();
    const [isDebugMode] = useQueryParam("debug", false);
    const { mapLayerOption, setMapLayer, mapLayer } = useMapLayer();

    const { dataLayer } = useDataLayerControl();
    const [isPolygonActive, setActivePolygon] = useState(false);
    const [styleLoaded, setStyleLoaded] = useState(false);

    // Map features state
    const [county, setCounty] = useState<County>();
    const [searchResult, _setSearchResult] = useState<ParcelSearchResponse>();

    // Render no features
    const emptyFilter = ["==", "code", ""];

    // mapFilter is the active map feature (parcel/county/zip)
    const [mapFilter, setMapFilter] = useState<MapFilter>();
    // savedList is the active Saved List
    const [savedList, setSavedList] = useState<ParcelSavedList>();
    const [isRulerActive, , removeIsRulerActive] = useQueryParam<boolean>(
        "ruler",
        null,
    );
    const { parcelQueryId, setParcelQuery, removeParcelQuery } = useParcel();
    const [popupInfo, setPopupInfo] = useState<PopupInfo>(null);
    const [ownerPropertyData, setOwnerPropertyData] = useState<OwnerPropertyState>();
    const updateMapOnLoad = useCallback(() => {
        if (!mapRef?.current) return;

        const map = mapRef.current.getMap();
        map.showTileBoundaries = isDebugMode;
        loadImages(map, IMAGES);
        setStyleLoaded(true);
    }, [isDebugMode]);

    const onLoad = () => {
        updateMapOnLoad();
    };

    // Check for querystring param to enable debug mode
    const [debugPoints, setDebugPoints] = useState<DebugPoint[]>();

    useEffect(() => {
        if (parcelQueryId) {
            fetch(`/api/property/parcels/${parcelQueryId}/`)
                .then((parcel) => {
                    const bounds = bbox(parcel.shape);
                    if (bounds) {
                        mapRef.current.fitBounds(
                            [
                                [bounds[0], bounds[1]],
                                [bounds[2], bounds[3]],
                            ],
                            {
                                padding: 200,
                                duration: 0,
                            },
                        );
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSearchResult = useCallback(
        (newSearchResult: ParcelSearchResponse, options?: { disableZoom: boolean }) => {
            _setSearchResult(newSearchResult);

            const parcels = newSearchResult?.results || [];

            // Clear prior selected zip/county
            setMapFilter(null);

            if (!options?.disableZoom) {
                // Fit map bounds to search results extent
                const parcelBounds = calculateBounds(parcels);
                if (parcelBounds) {
                    mapRef.current.fitBounds(parcelBounds, {
                        padding: 100,
                        duration: 0,
                    });
                }
            }

            // Reset feature state of parcels layer
            mapRef.current.removeFeatureState({
                source: "tiler_source",
                sourceLayer: "parcels",
            });

            // Set feature state of each parcel in parcels layer if the ID exists in parcels searchResults
            parcels.forEach((parcel: Parcel) => {
                mapRef.current.setFeatureState(
                    {
                        source: "tiler_source",
                        sourceLayer: "parcels",
                        id: parcel.PropertyID,
                    },
                    { searchResultMatch: true },
                );
            });
        },
        [mapRef, _setSearchResult, setMapFilter],
    );

    // Handle switching map layers. This is a destructive process in Mapbox GL
    // which is not gracefully handled by react-map-gl. Sources and layers must
    // be removed and then readded after styles are done loading to avoid
    // "Style is not done loading" errors.
    // Ref: https://github.com/visgl/react-map-gl/issues/1122
    const setMapStyle = useCallback(
        (mapStyle: string) => {
            const map = mapRef.current.getMap();

            // Restore map layers when styles are done loading
            map.once("style.load", () => {
                // Fixes an issue where MLS bg images don't load on style change
                updateMapOnLoad();
            });

            // Restore map feature state when tiles have reloaded
            map.once("idle", () => {
                setSearchResult(searchResult);
            });

            // Remove map layers
            setStyleLoaded(false);
            // @ts-ignore
            setMapLayer(mapStyle);
        },
        [searchResult, setMapLayer, setSearchResult, updateMapOnLoad],
    );

    const closePopup = () => setPopupInfo(null);

    const onClick = (e: MapLayerMouseEvent) => {
        if (!e.features || e.features.length === 0) {
            return;
        }
        for (const feature of e.features) {
            if (
                feature.source === "comp_source" &&
                feature.geometry.type === "Point" &&
                !isRulerActive &&
                !isPolygonActive
            ) {
                setPopupInfo({
                    latitude: feature.geometry.coordinates[1],
                    longitude: feature.geometry.coordinates[0],
                    type: "mls_comp",
                    title: "Comp details",
                    properties: feature.properties,
                });
                return;
            } else if (
                feature.source === "tiler_source" &&
                !isRulerActive &&
                !isPolygonActive
            ) {
                // Prevent when the ruler is active
                const parcel_features = e.features
                    .filter((f) => f.layer.id === "parcel_identify")
                    .sort((a, b) => (b.properties.apn < a.properties.apn ? 1 : -1))
                    // When clicking on tile boundaries, features can be returned multiple times
                    // This code filters the sorted list to only include each feature once
                    .filter((f, index, array) => {
                        if (index === array.length - 1) return true;
                        return f.properties.id !== array[index + 1].properties.id;
                    });

                if (parcel_features.length > 1) {
                    setPopupInfo({
                        latitude: e.lngLat.lat,
                        longitude: e.lngLat.lng,
                        type: "parcel_chooser",
                        title: "Multiple parcels",
                        properties: parcel_features,
                    });
                } else {
                    const id = feature?.id;

                    if (isNumber(id) && id !== parcelQueryId) {
                        setParcelQuery(id);
                        setOwnerPropertyData(null);
                    } else {
                        removeParcelQuery();
                    }

                    // Clear map filter if active parcel changed
                    if (id !== parcelQueryId) {
                        setMapFilter(null);
                    }
                }
                return;
            }
        }
    };

    const parcelQueryIdFilter = useMemo(
        () => ["==", "id", parcelQueryId || ""],
        [parcelQueryId],
    );

    const ownerQueryIdFilter = useMemo(() => {
        if (ownerPropertyData?.ownerParcelList) {
            return ["in", "id", ...ownerPropertyData?.ownerParcelList];
        }
        return parcelQueryIdFilter;
    }, [parcelQueryIdFilter, ownerPropertyData?.ownerParcelList]);

    // Set body class name on mount
    useEffect(() => {
        document.body.className = "parcel-viewer";
        return () => {
            // Clear class name on dismount
            document.body.className = "";
        };
    }, []);

    const context: ParcelViewerContextType = {
        setMapFilter,
        county,
        setCounty,
        savedList,
        setSavedList,
        setDebugPoints,
        searchResult,
        setSearchResult,
        setActivePolygon,
        isPolygonActive,
        ownerPropertyData,
        setOwnerPropertyData,
    };

    // Apply map filter
    let zipFilter = emptyFilter;
    let countyFilter = emptyFilter;
    let parcelFilter = emptyFilter;
    let identifyFilter = emptyFilter;
    if (mapFilter) {
        const { identifyLayer, filter, inverseFilter } = mapFilter;
        identifyFilter = filter;
        if (identifyLayer === "county_identify") {
            countyFilter = inverseFilter;
        } else if (identifyLayer === "zip_identify") {
            zipFilter = inverseFilter;
        } else if (identifyLayer === "parcel_identify") {
            parcelFilter = inverseFilter;
        }
    }

    const searchResultGeojson = useMemo<FeatureCollection>(
        () => ({
            type: "FeatureCollection",
            features:
                searchResult?.results
                    ?.filter((parcel) => parcel?.point)
                    ?.map((parcel) => ({
                        id: parcel.PropertyID,
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: parcel.point,
                        },
                        properties: {},
                    })) || [],
        }),
        [searchResult],
    );

    const onError = (e: any) => {
        if (e.message === "Map is not supported by this browser") {
            // Handle Web GL init error raised by react-map-gl
            // TODO: friendly error message
            console.log("WebGL init error. Refresh page to continue.");
        } else if (e.error?.status === 400) {
            // Ignore HTTP 400 Bad Request tile requests
        } else {
            console.error(e);
        }
    };

    const formatCompsFilter = (compsFilter: {
        minAcres: any;
        maxAcres: any;
    }): any[] => {
        let { minAcres, maxAcres } = compsFilter;
        try {
            minAcres = parseFloat(minAcres);
        } catch (e) {
            minAcres = null;
        }

        try {
            maxAcres = parseFloat(maxAcres);
        } catch (e) {
            maxAcres = null;
        }

        const f: any[] = ["all"];
        if (!minAcres && !maxAcres) {
            return f;
        }
        if (minAcres) {
            f.push([">=", ["to-number", ["get", "lot_size"]], minAcres]);
        }
        if (maxAcres) {
            f.push(["<=", ["to-number", ["get", "lot_size"]], maxAcres]);
        }
        return f;
    };
    const compsFilter = useMemo(
        () => formatCompsFilter(dataLayer.compsFilter),
        [dataLayer.compsFilter],
    );

    const sourcesAndLayers = useMemo(() => {
        if (!styleLoaded) {
            return null;
        }
        return (
            <>
                {popupInfo && popupInfo.type === "mls_comp" && (
                    <CompPopup
                        key={popupInfo.properties.id}
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        onClose={closePopup}
                        title={popupInfo.title}
                        properties={popupInfo.properties}
                    />
                )}
                {popupInfo && popupInfo.type === "parcel_chooser" && (
                    <ParcelChooserPopup
                        key={popupInfo.properties.id}
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        onClose={closePopup}
                        title={popupInfo.title}
                        properties={popupInfo.properties}
                        setPopupInfo={setPopupInfo}
                    />
                )}
                <Source {...parcelSource}>
                    <Layer {...parcelIdentify} />
                    <Layer {...parcelHighlightShadow} filter={parcelFilter} />
                    <Layer {...parcelHighlightOutline} />
                    {Boolean(ownerPropertyData?.ownerParcelId) ? (
                        <Layer {...ownerSelectedOutline} filter={ownerQueryIdFilter} />
                    ) : (
                        <Layer
                            {...parcelSelectedOutline}
                            filter={parcelQueryIdFilter}
                        />
                    )}
                </Source>
                <Source {...ownerSource}>
                    {dataLayer.ownersLayer && <Layer {...ownersLabelStyle} />}
                </Source>
                <Source {...wetlandsSource}>
                    {dataLayer.wetLands && <Layer {...wetlandsFill} />}
                    {dataLayer.wetLands && <Layer {...wetlandsOutline} />}
                    {dataLayer.surfaceWater && <Layer {...surfaceWaterFill} />}
                    {dataLayer.surfaceWater && <Layer {...surfaceWaterOutline} />}
                </Source>
                <Source {...buildingsSource}>
                    {dataLayer.buildingLayer && <Layer {...buildingsOutline} />}
                </Source>
                <Source {...roadsSource}>
                    {dataLayer.roadsLayer && <Layer {...roadsOutline} />}
                </Source>
                <Source {...floodzoneSource}>
                    {dataLayer.floodZoneLayer && <Layer {...floodzoneCoastal} />}
                    {dataLayer.floodZoneLayer && <Layer {...floodzoneFloodway} />}
                    {dataLayer.floodZoneLayer && <Layer {...floodzone100} />}
                    {dataLayer.floodZoneLayer && <Layer {...floodzone500} />}
                </Source>

                {/* Hide search result clusters while drawing polygons on the map */}
                {!isPolygonActive && (
                    <Source
                        type="geojson"
                        data={searchResultGeojson}
                        cluster={true}
                        clusterMaxZoom={13}
                        tolerance={0.5}
                    >
                        <Layer {...searchResultsCluster} />
                        <Layer {...searchResultsClusterLabel} />
                    </Source>
                )}
                <Source {...countySource}>
                    {dataLayer.countyLayer && <Layer {...countyOutline} />}
                    {dataLayer.countyLayer && <Layer {...countyNameLayer} />}
                    <Layer {...countyIdentify} />
                    <Layer {...countyHighlightOutline} filter={identifyFilter} />
                    <Layer {...countyHighlightShadow} filter={countyFilter} />
                </Source>
                <Source {...maptilerSource}>
                    {dataLayer.zipLayer && <Layer {...zipOutline} />}
                    <Layer {...zipIdentify} />
                    <Layer {...zipHighlightOutline} filter={identifyFilter} />
                    <Layer {...zipHighlightShadow} filter={zipFilter} />
                </Source>
                <Source {...compSource}>
                    {dataLayer.mlsComps && (
                        <Layer {...compsCircleStyle} filter={compsFilter} />
                    )}
                    {dataLayer.mlsComps && (
                        <Layer {...compsLabelStyle} filter={compsFilter} />
                    )}
                </Source>

                {!isRulerActive && (
                    <Source
                        id="mapbox-dem"
                        type="raster-dem"
                        url="mapbox://mapbox.mapbox-terrain-dem-v1"
                    >
                        <Layer id="terrain" type="hillshade" source="mapbox-dem" />
                    </Source>
                )}

                {((isDebugMode && debugPoints) || []).map(({ point, color }, i) => (
                    <Marker
                        key={i}
                        longitude={point[0]}
                        latitude={point[1]}
                        color={color}
                        draggable
                    ></Marker>
                ))}
                <GoogleSourceLayer isShown={mapLayer === "google"} />

                {dataLayer.contourLayer && <ContourLayer />}
            </>
        );
    }, [
        styleLoaded,
        popupInfo,
        parcelFilter,
        ownerPropertyData?.ownerParcelId,
        ownerQueryIdFilter,
        parcelQueryIdFilter,
        dataLayer.ownersLayer,
        dataLayer.wetLands,
        dataLayer.surfaceWater,
        dataLayer.buildingLayer,
        dataLayer.roadsLayer,
        dataLayer.floodZoneLayer,
        dataLayer.countyLayer,
        dataLayer.zipLayer,
        dataLayer.mlsComps,
        dataLayer.contourLayer,
        isPolygonActive,
        searchResultGeojson,
        identifyFilter,
        countyFilter,
        zipFilter,
        compsFilter,
        isRulerActive,
        isDebugMode,
        debugPoints,
        mapLayer,
    ]);

    const { user } = useContext(UserContext);

    return (
        <ParcelViewerContext.Provider value={context}>
            <div className={"parcel-viewer-wrapper lui-flex lui-flex-col"}>
                <FreeTrialNotification user={user} />
                <Map
                    hash
                    initialViewState={initialViewState}
                    ref={mapRef}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    mapStyle={mapLayerOption}
                    minZoom={4}
                    maxZoom={20}
                    onClick={onClick}
                    onLoad={onLoad}
                    interactiveLayerIds={[
                        "parcel_identify",
                        "comps-circle",
                        "comps-label",
                        "floodzone_500_fill",
                        "floodzone_100_fill",
                        "floodzone_coastal_fill",
                        "floodzone_floodway_fill",
                    ]}
                    onError={onError}
                    terrain={
                        isRulerActive
                            ? null
                            : {
                                  source: "mapbox-dem",
                                  exaggeration: 1,
                              }
                    }
                    touchPitch={true}
                    cursor={isPolygonActive ? "crosshair" : "pointer"}
                >
                    {sourcesAndLayers}

                    <MapHeader
                        setSearchResult={setSearchResult}
                        setShowFilterPanel={() => {
                            removeParcelQuery();
                        }}
                    />

                    {parcelQueryId && (
                        <ParcelDetail
                            parcelID={parcelQueryId}
                            onClose={() => removeParcelQuery()}
                        />
                    )}

                    <FloodZoneHoverTooltip />

                    {/* Top Right Map Options */}
                    <div className=" lui-flex lui-flex-col lui-gap-3 lui-absolute lui-right-0 lui-top-0 lui-m-6">
                        <Button
                            variant="secondary"
                            icon="LandInsights"
                            href="/"
                            openInNewTab
                        />
                    </div>

                    {isDebugMode && (
                        <>
                            <InspectControl />
                        </>
                    )}

                    {/* Bottom Right Map Options */}
                    <div className="lui-flex lui-flex-col lui-gap-3 lui-absolute lui-right-0 lui-bottom-0 lui-m-6">
                        <DataLayerControl />
                        <MapToolsControl />
                        <MapLayerControl setLayer={setMapStyle} />
                        <ZoomControls />
                    </div>

                    {/* Bottom Left Map Options */}
                    <div className="lui-flex lui-flex-col lui-gap-3 lui-absolute lui-left-0 lui-bottom-2 lui-my-6 lui-mx-2">
                        <MapHelpControl />
                    </div>

                    {/* Center bottom options */}
                    {isRulerActive && (
                        <div className="lui-flex lui-absolute lui-bottom-0 lui-left-1/2 -lui-translate-x-1/2 lui-justify-center lui-gap-3 lui-m-6 lui-w-fit ">
                            <div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        removeIsRulerActive();
                                    }}
                                    icon="Ruler"
                                >
                                    Exit Ruler
                                </Button>
                            </div>
                        </div>
                    )}
                </Map>
            </div>
        </ParcelViewerContext.Provider>
    );
}

function getBounds(parcel: Parcel): LngLatBounds {
    const lng0 = parcel.bbox ? parcel.bbox[0] : parcel.point[0];
    const lat0 = parcel.bbox ? parcel.bbox[1] : parcel.point[1];
    const lng1 = parcel.bbox ? parcel.bbox[2] : parcel.point[0];
    const lat1 = parcel.bbox ? parcel.bbox[3] : parcel.point[1];
    return new LngLatBounds([lng0, lat0], [lng1, lat1]);
}

function calculateBounds(parcels: Parcel[]): LngLatBounds {
    if (!parcels || parcels.length === 0) {
        return null;
    }
    const bounds = new LngLatBounds();
    for (const parcel of parcels) {
        if (parcel?.point) {
            bounds.extend(getBounds(parcel));
        }
    }
    return bounds;
}
