import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { AGGrid } from "components";
import { post, fetch, formatNumber, parseErrorResponse, formatDate } from "functions";
import { Loading } from "library";

const ReactSwal = withReactContent(Swal);

export default function RealtorReportDetail() {
    const { state, county } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    const refresh = useCallback(async () => {
        // Handle case where user manually navigates to: /realtor_report/:state
        if (!county) {
            navigate("/home/realtor_report");
            return;
        }
        try {
            const data = await fetch(
                `/api/analytics/realtor_report/${state}/${county}/`,
            );
            setLoading(false);
            setData(data);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    }, [state, county, navigate]);

    const showModal1 = async () => {
        const result = await ReactSwal.fire({
            title: "Have A Deal?",
            icon: "info",
            html: (
                <>
                    Before we connect you and the agent, we want to make sure you have a
                    deal in hand to be respectful of the agent time's time.
                </>
            ),
            input: "checkbox",
            inputValidator: (result) => {
                return !result && "You must agree to continue";
            },
            inputPlaceholder: "Yes, I have a Double Close deal ready",
            confirmButtonText: "Continue",
            showCancelButton: true,
        });
        return result.value;
    };

    const showModal2 = async () => {
        const result = await ReactSwal.fire({
            title: "You're About To Be Connected...",
            icon: "info",
            html: (
                <>
                    Press "Continue" to be connected with the agent via your email and
                    their email.
                </>
            ),
            confirmButtonText: "Continue",
            showCancelButton: true,
        });
        return result.value;
    };

    const showSuccessModal = () => {
        ReactSwal.fire({
            title: "You've Been Connected!",
            icon: "success",
            html: <>You should receive an email in just a moment.</>,
            confirmButtonText: "OK",
        });
    };

    const unlockAgent = useCallback(
        async (id) => {
            let ok = false;
            ok = await showModal1();
            if (!ok) {
                return;
            }
            ok = await showModal2();
            if (!ok) {
                return;
            }

            try {
                await post(`/api/analytics/double_close_agent_claim/`, {
                    agent: id,
                    county,
                    state,
                });
                refresh();
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
                return;
            }

            showSuccessModal();
        },
        [refresh],
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <DoubleCloseAgents
                state={state}
                county={county}
                agents={data?.double_close_agents}
                unlockAgent={unlockAgent}
            />
            <Agents county={county} agents={data?.agents} />
        </>
    );
}

function DoubleCloseAgents({ state, county, agents, unlockAgent }) {
    const columns = [
        { headerName: "Name", field: "name", cellRenderer: BlurryText },
        { headerName: "Phone", field: "phone", cellRenderer: BlurryText },
        { headerName: "Email", field: "email", cellRenderer: BlurryText },
        {
            headerName: "Counties Served",
            field: "counties_served",
            valueFormatter: ({ value }) => formatCountiesServed(state, value),
        },
        {
            headerName: "Date Unlocked",
            cellRenderer: UnlockAgent,
            cellRendererParams: { unlockAgent },
        },
    ];

    return (
        <div className="card mb-4">
            <div className="card-header pb-0">
                <h5>{county} Double Close Agents</h5>
            </div>
            <div className="card-body">
                {agents?.length > 0 ? (
                    <AGGrid rows={agents} columns={columns} height="auto" fill_table />
                ) : (
                    <p className="text-center">No DC Agents Available</p>
                )}
            </div>
        </div>
    );
}

function formatCountiesServed(state, countiesServed) {
    // Filter countiesServed by state
    const countyTuples = countiesServed?.filter((tuple) => tuple[0] === state) || [];
    // Select county name from each tuple
    const counties = countyTuples.map((tuple) => tuple[1]);
    if (counties.indexOf("ALL") !== -1) {
        return "Entire State";
    }
    return counties.join(", ");
}

function Agents({ county, agents }) {
    const columns = [
        {
            headerName: "Brokerage",
            field: "brokerage",
            cellRenderer: BrokerageLink,
        },
        {
            headerName: "Sold",
            field: "brokerage_sold",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        {
            headerName: "Active",
            field: "brokerage_active",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        { headerName: "Broker Phone", field: "brokerage_phone" },
        { headerName: "Agent", field: "agent", cellRenderer: AgentLink },
        {
            headerName: "Sold",
            field: "agent_sold",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        {
            headerName: "Active",
            field: "agent_active",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        { headerName: "Agent Phone", field: "agent_phone" },
    ];

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>{county} Brokers And Realtors</h5>
            </div>
            <div className="card-body">
                {(agents.length && (
                    <AGGrid rows={agents} columns={columns} height="auto" />
                )) ||
                    `No data available.`}
            </div>
        </div>
    );
}

function BrokerageLink({ data }) {
    const { brokerage, brokerage_search_url } = data;
    return (
        <Link
            className="text-decoration-underline"
            to={brokerage_search_url}
            target="_blank"
        >
            {brokerage}
        </Link>
    );
}

function AgentLink({ data }) {
    const { agent, agent_search_url } = data;
    return (
        <Link
            className="text-decoration-underline"
            to={agent_search_url}
            target="_blank"
        >
            {agent}
        </Link>
    );
}

function BlurryText({ value, data }) {
    const { unlocked_at } = data;
    if (unlocked_at) {
        return value;
    }
    return (
        <span
            style={{
                textShadow: "0 0 8px black",
                paddingLeft: 8,
                color: "transparent",
                userSelect: "none",
            }}
        >
            {value}
        </span>
    );
}

function UnlockAgent({ data, unlockAgent }) {
    const { id, unlocked_at } = data;
    if (unlocked_at) {
        return formatDate(unlocked_at);
    }
    return (
        <button className="btn btn-primary" onClick={() => unlockAgent(id)}>
            Unlock
        </button>
    );
}
