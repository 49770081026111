import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { isNumber } from "lodash";
import { formatNumber } from "@src/functions";
import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import { Icon } from "@src/land_ui/icon/icon";
import { Modal } from "@src/land_ui/modal/modal";
import { Popover } from "@src/land_ui/popover/popover";
import { Sidebar } from "@src/land_ui/sidebar/sidebar";
import { Tooltip } from "@src/land_ui/tooltip/tooltip";
import { Typography } from "@src/land_ui/typography/typography";
import { useExportsCreateLegacyScrubCreate } from "@src/orval/gen/api";
import { type PaginatedParcelExportReadOnlyList } from "@src/orval/gen/model";
import { PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";
import { type MenuSidebarProps } from "./useMenuSidebar";
import { ErrorMessageBox } from "@src/components/ErrorMessageBox";

interface ExportsMenuItemProps extends MenuSidebarProps {
    filterCount: number;
    exportListResult?: PaginatedParcelExportReadOnlyList;
}

export function ExportsMenuItem({
    dispatch,
    filterCount,
    exportListResult,
}: ExportsMenuItemProps) {
    return (
        <>
            <Sidebar.Header>
                <div className="lui-flex lui-gap-6">
                    <Button
                        variant="base"
                        icon="LeftArrow"
                        className="lui-flex lui-items-center"
                        onClick={() => {
                            dispatch({ type: "SET_VIEW", view: "MAIN" });
                        }}
                    />

                    <Typography
                        size="xl"
                        weight="medium"
                        className="lui-flex lui-items-center lui-gap-1"
                    >
                        Exports
                        <Typography
                            size="xl"
                            weight="medium"
                            color="gray-700"
                            className="lui-items-center"
                        >
                            ({filterCount})
                        </Typography>
                    </Typography>
                </div>
            </Sidebar.Header>
            <Sidebar.Content>
                <ExportItem dispatch={dispatch} exportListResult={exportListResult} />
            </Sidebar.Content>
        </>
    );
}

interface ExportItemProps extends MenuSidebarProps {
    exportListResult?: PaginatedParcelExportReadOnlyList;
}

export function ExportItem({ dispatch, exportListResult }: ExportItemProps) {
    const [showModal, setShowModal] = useState(false);

    const queryClient = useQueryClient();
    const { mutate: createLegacyLandScrub, error } = useExportsCreateLegacyScrubCreate({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["exportList"],
                });
            },
            onSettled: () => {
                // Show modal upon success or failure
                setShowModal(true);
            },
        },
    });

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowModal(false);
    };

    return (
        <div>
            {exportListResult?.results.map((parcelExport) => {
                return (
                    <div
                        key={parcelExport.id}
                        className="lui-p-4 lui-px-6 lui-flex lui-flex-col lui-gap-2.5 lui-border-b lui-border-solid lui-border-gray-50"
                    >
                        <div className="lui-flex lui-justify-between">
                            <div className="lui-flex lui-flex-col lui-gap-2.5">
                                <div className="lui-flex lui-flex-col lui-gap-1">
                                    <Button
                                        variant="base"
                                        onClick={() => {
                                            dispatch({
                                                type: "SET_SELECTED_EXPORT",
                                                export: parcelExport,
                                            });
                                            dispatch({
                                                type: "SET_VIEW",
                                                view: "EXPORT_PARCELS_LIST",
                                            });
                                        }}
                                    >
                                        <Typography
                                            variant="span"
                                            size="md"
                                            weight="medium"
                                        >
                                            {parcelExport?.name}
                                        </Typography>
                                    </Button>
                                    <Typography
                                        variant="span"
                                        size="sm"
                                        weight="bold"
                                        color="primary-500"
                                    >
                                        {formatNumber(parcelExport?.total_rows ?? 0)}{" "}
                                        Parcels
                                    </Typography>
                                </div>

                                <div className="lui-flex lui-gap-3">
                                    {parcelExport?.skip_trace?.status === "success" ? (
                                        <ExportStatusLabel
                                            label="Skip hit rate"
                                            status={
                                                Boolean(parcelExport?.skip_trace)
                                                    ? `${parcelExport?.skip_trace?.hit_rate}%`
                                                    : PLACEHOLDER_LABEL
                                            }
                                        />
                                    ) : (
                                        <ExportStatusLabel
                                            label="Skip hit rate"
                                            status={
                                                parcelExport?.skip_trace?.status ??
                                                PLACEHOLDER_LABEL
                                            }
                                        />
                                    )}

                                    <Divider />

                                    {parcelExport?.status === "success" &&
                                    (parcelExport?.scrub_duplicates ||
                                        parcelExport?.land_scrub) ? (
                                        <Tooltip
                                            showArrow
                                            placement="top"
                                            trigger={
                                                <Button
                                                    variant="base"
                                                    className="lui-bg-none"
                                                >
                                                    <Typography
                                                        weight="regular"
                                                        color="gray-700"
                                                        size="sm"
                                                    >
                                                        After scrub{" "}
                                                        <Typography
                                                            variant="span"
                                                            weight="bold"
                                                            size="sm"
                                                        >
                                                            {formatNumber(
                                                                parcelExport.total_clean_rows,
                                                            )}
                                                        </Typography>
                                                    </Typography>
                                                </Button>
                                            }
                                            content={
                                                <div className="lui-flex lui-flex-col lui-gap-2 lui-py-2">
                                                    <Typography
                                                        size="sm"
                                                        weight="bold"
                                                        className="lui-flex lui-gap-5 lui-justify-between"
                                                        color="gray-50"
                                                    >
                                                        <div>
                                                            Maximum wetlands cover
                                                        </div>
                                                        <span className="ml-2">
                                                            {isNumber(
                                                                parcelExport?.land_scrub
                                                                    ?.wetlands_allowed,
                                                            )
                                                                ? `${parcelExport?.land_scrub?.wetlands_allowed}%`
                                                                : "—"}
                                                        </span>
                                                    </Typography>
                                                    <Typography
                                                        size="sm"
                                                        weight="bold"
                                                        className="lui-flex lui-gap-5  lui-justify-between"
                                                        color="gray-50"
                                                    >
                                                        <div>Maximum flood zone</div>
                                                        <span className="ml-2">
                                                            {isNumber(
                                                                parcelExport?.land_scrub
                                                                    ?.flood_zone_allowed,
                                                            )
                                                                ? `${parcelExport?.land_scrub?.flood_zone_allowed}%`
                                                                : "—"}
                                                        </span>
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    ) : (
                                        <ExportStatusLabel
                                            label="After scrub"
                                            status={
                                                parcelExport?.land_scrub?.status ??
                                                PLACEHOLDER_LABEL
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="lui-flex lui-items-center">
                                <Popover
                                    trigger={
                                        <Button variant="base" className="lui-bg-none">
                                            <Icon
                                                name="DotsHorizontal"
                                                color="gray-700"
                                            />
                                        </Button>
                                    }
                                    placement="bottom-end"
                                    className="lui-bg-white lui-shadow-sm lui-rounded-xl lui-min-w-32 lui-overflow-hidden"
                                >
                                    <div className="lui-flex lui-flex-col lui-text-start">
                                        <Button
                                            disabled={!parcelExport?.file}
                                            openInNewTab
                                            href={parcelExport?.file}
                                            variant="base"
                                            className="lui-text-start lui-py-2 lui-px-4 hover:lui-bg-gray-50"
                                        >
                                            Download All{" "}
                                            <Typography
                                                variant="span"
                                                size="sm"
                                                weight="regular"
                                                color="gray-700"
                                            >
                                                {!parcelExport?.file ? "(Pending)" : ""}
                                            </Typography>
                                        </Button>
                                        <Button
                                            disabled={!parcelExport?.file_clean}
                                            openInNewTab
                                            href={parcelExport?.file_clean}
                                            variant="base"
                                            className="lui-text-start lui-py-2 lui-px-4 hover:lui-bg-gray-50"
                                        >
                                            Download Clean{" "}
                                            <Typography
                                                variant="span"
                                                size="sm"
                                                weight="regular"
                                                color="gray-700"
                                            >
                                                {!parcelExport?.file_clean
                                                    ? "(Pending)"
                                                    : ""}
                                            </Typography>
                                        </Button>
                                        {parcelExport.land_scrub !== null &&
                                            (parcelExport.legacy_land_scrub !== null ? (
                                                <Button
                                                    openInNewTab
                                                    href={`/home/land_scrubbing/results/${parcelExport.legacy_land_scrub}`}
                                                    variant="base"
                                                    className="lui-text-start lui-py-2 lui-px-4 hover:lui-bg-gray-50"
                                                >
                                                    View Legacy Land Scrubbing Job
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="base"
                                                    className="lui-text-start lui-py-2 lui-px-4 hover:lui-bg-gray-50"
                                                    onClick={() =>
                                                        createLegacyLandScrub({
                                                            id: parcelExport.id,
                                                        })
                                                    }
                                                >
                                                    Create Legacy Land Scrubbing Job
                                                </Button>
                                            ))}
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Modal isOpen={showModal} setIsOpen={setShowModal}>
                <form onSubmit={onSubmit}>
                    <Modal.Header preventAutoFocus>
                        Land Scrubbing Job Created
                    </Modal.Header>
                    <Modal.Content className="lui-p-6">
                        {Boolean(error) ? (
                            <ErrorMessageBox isShown={Boolean(error)}>
                                There was an error while trying to create the legacy
                                Land Scrubbing Job.{" "}
                                {parseErrorResponse(error) || "Unknown error"}.
                            </ErrorMessageBox>
                        ) : (
                            <p>
                                Your legacy scrub has started and will be available on
                                the{" "}
                                <a href="/home/land_scrubbing/" target="_blank">
                                    Land Scrubbing
                                </a>{" "}
                                page once it has been completed.
                            </p>
                        )}
                    </Modal.Content>
                    <Modal.Footer className="lui-flex lui-gap-3 lui-justify-end">
                        <Button variant="primary" type="submit">
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

function ExportStatusLabel({ label, status }: { label: string; status: string }) {
    return (
        <Button variant="base" className="lui-bg-none">
            <Typography weight="regular" color="gray-700" size="sm">
                {label}{" "}
                <Typography
                    variant="span"
                    weight="bold"
                    size="sm"
                    className="lui-capitalize"
                >
                    {status}
                </Typography>
            </Typography>
        </Button>
    );
}

function parseErrorResponse(error: any): string {
    try {
        return error?.response?.data?.error || "";
    } catch (ex) {
        return "";
    }
}
