/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Land Insights API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `standard` - Standard
* `premium` - Premium
 */
export type ReportTypeEnum = typeof ReportTypeEnum[keyof typeof ReportTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportTypeEnum = {
  standard: 'standard',
  premium: 'premium',
} as const;
