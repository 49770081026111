import { ExportDetailCard } from "@src/components/parcel_detail/parcel_detail_card";
import { useParcel } from "@src/hooks/useParcel";
import { useSidebarControl } from "@src/hooks/useSidebarControl";
import { Divider } from "@src/land_ui/Divider/Divider";
import { Sidebar } from "@src/land_ui/sidebar/sidebar";
import { Typography } from "@src/land_ui/typography/typography";
import { useParcelsOwnerPortfolioRetrieve } from "@src/orval/gen/api";
import { useMapContext } from "@src/pages/parcel_viewer/context";
import { getOwnerFullName } from "@src/pages/parcel_viewer/controls/detail";
import { useEffect } from "react";

export function OwnerPropertySidebar() {
    const { setOwnerPropertyData, ownerPropertyData, setSearchResult } =
        useMapContext();
    const { isSidebarOpen, setIsSidebarOpen } = useSidebarControl();

    const { parcel } = useParcel({
        enabled: true,
        parcelId: ownerPropertyData?.ownerParcelId,
    });

    const { data } = useParcelsOwnerPortfolioRetrieve(
        ownerPropertyData?.ownerParcelId,
        {
            query: {
                enabled: Boolean(ownerPropertyData?.ownerParcelId),
                queryKey: [
                    "parcelsOwnerPortfolioRetrieve",
                    ownerPropertyData?.ownerParcelId,
                ],
            },
        },
    );

    useEffect(() => {
        if (data?.results?.length > 0 && isSidebarOpen("owner")) {
            setOwnerPropertyData({
                ownerParcelId: ownerPropertyData?.ownerParcelId,
                ownerParcelList: data.results.map((e) => e.PropertyID),
            });
            setSearchResult({
                results: data.results.map((e) => ({
                    PropertyID: e.PropertyID,
                    point: e.point.flat(), // point is returns as readonly in FullParcel type
                    bbox: null as any, // bbox is currently missing in FullParcel
                })),
                count: data.results.length,
                more_results: false,
            });
        }
        // We only want to set the owner property data when the data is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Sidebar
            isOpen={isSidebarOpen("owner") && Boolean(ownerPropertyData?.ownerParcelId)}
            setIsOpen={(isOpenState) => {
                setIsSidebarOpen(isOpenState, "owner");
            }}
            removeBackdrop
        >
            <Sidebar.Header>
                <div>
                    <Typography
                        size="lg"
                        weight="bold"
                        color="gray-1000"
                        className="lui-capitalize"
                    >
                        {getOwnerFullName(parcel)}
                    </Typography>
                </div>
            </Sidebar.Header>

            <Sidebar.Content>
                <div className="lui-grid lui-grid-cols-2 lui-py-3 lui-px-6 lui-gap-6">
                    <OwnerCell title={"Total Parcels"} content={data?.total_parcels} />
                    <OwnerCell title={"Total Acreage"} content={data?.total_acres} />

                    <OwnerCell title={"Total States"} content={data?.total_states} />
                    <OwnerCell
                        title={"Total Counties"}
                        content={data?.total_counties}
                    />
                </div>

                <Divider horizontal />

                <div className=" lui-h-full lui-pb-6">
                    {data?.results?.map((e, i) => {
                        return (
                            <ExportDetailCard
                                key={`owner-property-${i}-${e.APN}`}
                                parcel={e}
                            />
                        );
                    })}
                </div>
            </Sidebar.Content>
        </Sidebar>
    );
}

function OwnerCell({ title, content }: { title: string; content: string | number }) {
    return (
        <div className="lui-flex lui-flex-col lui-items-center">
            <Typography size="sm" color="gray-700">
                {title}
            </Typography>
            <Typography size="sm" weight="medium" className="lui-mt-0.5">
                {content}
            </Typography>
        </div>
    );
}
