import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { SatelliteIcon } from "@src/land_ui/icon/icons/Satellite";
import { StreetIcon } from "@src/land_ui/icon/icons/Street";
import { useQuery } from "@tanstack/react-query";
import Axios, { type AxiosResponse } from "axios";
import clsx from "clsx";
import { Layer, Source } from "react-map-gl";
import { useLocalStorage } from "usehooks-ts";
import { ControlPopover } from "./control_popover";

export const LayerOptions = {
    satellite: "mapbox://styles/kmlandinsights/cm3nbi4pl006z01rbawq0fpnn",
    street: "mapbox://styles/mapbox/outdoors-v12",
    naip: "mapbox://styles/kmlandinsights/cm6jo0laz002q01rb0pv64425",

    // Note: Using a different style URL for the Google layer to ensure the map updates correctly when toggled
    google: "mapbox://styles/mapbox/satellite-streets-v12",
} as const;

interface MapLayerControlProps {
    setLayer: (layer: string) => void;
}

export function useMapLayer() {
    const [mapLayer, setMapLayer] = useLocalStorage<keyof typeof LayerOptions>(
        "landinsights-map-layer-setting",
        "satellite", // default value
    );

    return {
        mapLayer,
        setMapLayer,
        mapLayerOption: LayerOptions[mapLayer],
    };
}

interface mapLayerOptionProps {
    label: string;
    name: keyof typeof LayerOptions;
    icon: JSX.Element;
}

const mapLayerOptions: mapLayerOptionProps[] = [
    {
        label: "Hi-Res",
        name: "google",
        icon: <SatelliteIcon />,
    },
    {
        label: "NAIP",
        name: "naip",
        icon: <SatelliteIcon />,
    },
    {
        label: "Satellite",
        name: "satellite",
        icon: <SatelliteIcon />,
    },
    {
        label: "Street",
        name: "street",
        icon: <StreetIcon />,
    },
] as const;

export function MapLayerControl({ setLayer }: MapLayerControlProps) {
    const { mapLayer, setMapLayer } = useMapLayer();

    return (
        <ControlPopover
            icon="Globe"
            tooltipContent="Map Layers"
            title="Map Layers"
            placement="left"
        >
            {mapLayerOptions.map((layer) => (
                <div
                    key={layer.name}
                    className={clsx("lui-px-4 lui-py-2 ", {
                        "lui-bg-primary-50 hover:lui-bg-primary-50":
                            layer.name === mapLayer,
                        "hover:lui-bg-gray-100": layer.name !== mapLayer,
                    })}
                >
                    <Button
                        variant="base"
                        onClick={() => {
                            setLayer(LayerOptions[layer.name]);
                            setMapLayer(layer.name);
                        }}
                        fullWidth
                        className="lui-p-0"
                    >
                        <span
                            className={clsx(
                                "lui-flex lui-justify-between lui-w-full lui-gap-2.5 lui-items-center",
                            )}
                        >
                            <span
                                className={clsx(
                                    "lui-flex lui-grow lui-gap-2.5 lui-items-center lui-no-underline lui-w-24",
                                )}
                            >
                                <span
                                    className={clsx(
                                        "lui-w-9 lui-border-2 lui-border-solid lui-rounded-lg",
                                        {
                                            "lui-border-primary-500":
                                                layer.name === mapLayer,
                                            "lui-border-gray-50":
                                                layer.name !== mapLayer,
                                        },
                                    )}
                                >
                                    {layer.icon}
                                </span>
                                <span>{layer.label}</span>
                            </span>
                            <span>
                                {mapLayer === layer.name && (
                                    <Icon name="Check" size="md" color="primary-500" />
                                )}
                            </span>
                        </span>
                    </Button>
                </div>
            ))}
        </ControlPopover>
    );
}

interface GoogleResponseData {
    session: string;
    expiry: string;
    tileWidth: number;
    imageFormat: string;
    tileHeight: number;
}

const GOOGLE_API_KEY = import.meta.env.VITE_APP_PUBLIC_GOOGLE_API_MAP;
export function GoogleSourceLayer({ isShown }: { isShown: boolean }) {
    const { data } = useQuery({
        enabled: isShown,
        retry: 1,
        queryKey: ["google", "layer"],
        queryFn: async () => {
            const response = await Axios.post<any, AxiosResponse<GoogleResponseData>>(
                "https://tile.googleapis.com/v1/createSession",
                {
                    mapType: "satellite",
                    language: "en-US",
                    region: "US",
                },
                {
                    params: {
                        key: GOOGLE_API_KEY,
                    },
                },
            );

            return response.data;
        },
    });

    if (!(data && isShown)) return null;

    return (
        <>
            <Source
                id="google-satellite"
                type="raster"
                tiles={[
                    `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${data?.session}&key=${GOOGLE_API_KEY}`,
                ]}
            />
            <Layer
                id="google-satellite-layer"
                type="raster"
                source="google-satellite"
                beforeId="parcel_identify"
            />
        </>
    );
}
