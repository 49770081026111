import { zodResolver } from "@hookform/resolvers/zod";
import { type QueryObserverResult, useQueryClient } from "@tanstack/react-query";
import { bbox, combine, featureCollection } from "@turf/turf";
import { first, get, isArray, toString } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useMap } from "react-map-gl";
import { useDebouncedCallback } from "use-debounce";
import * as z from "zod";
import { fetchMapboxFeature, formatNumber } from "@src/functions";
import {
    INTEGER_PERCENT_VALIDATOR,
    POSITIVE_INTEGER_VALIDATOR,
    POSITIVE_NUMBER_VALIDATOR,
    US_ZIP_CODE_VALIDATOR,
} from "@src/functions/validation_utils";
import { Accordion } from "@src/land_ui/accordion/accordion";
import { Autocomplete, useAutocomplete } from "@src/land_ui/autocomplete/autocomplete";
import { Badge } from "@src/land_ui/badge/badge";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Input } from "@src/land_ui/input/input";
import { SelectionGroupFormController } from "@src/land_ui/selection_group/selection_group";
import { Sidebar, useSidebar } from "@src/land_ui/sidebar/sidebar";
import { Toggle } from "@src/land_ui/toggle_button/Toggle";
import { Typography } from "@src/land_ui/typography/typography";
import {
    autocompleteLookupRetrieve,
    autocompleteRetrieve,
    useParcelsSearchRetrieve,
} from "@src/orval/gen/api";
import {
    type AutocompleteLookupRetrieveParams,
    type AutocompleteRetrieveParams,
    type ListItem,
    type ParcelExportFormState,
    type ParcelSearchResponse,
    type ParcelsListParams,
    ReportTypeEnum,
    SizePreferenceEnum,
} from "@src/orval/gen/model";
import {
    type SearchResultOptions,
    UserContext,
    ParcelViewerContext,
    useMapContext,
} from "@src/pages/parcel_viewer/context";
import {
    findFeatureOnMap,
    searchMapbox,
} from "@src/pages/parcel_viewer/controls/search";
import {
    type CountyOption,
    type SaveFilterParcelsListParams,
} from "@src/pages/parcel_viewer/types";
import { type FeaturePolygon, usePolygon } from "@src/components/map_tools/map_polygon";
import { TagList } from "@src/components/tag_list/tag_list";
import { MapFilterParcel } from "./map_filter_parcel_list";
import { useCountdown } from "usehooks-ts";
import clsx from "clsx";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";

const LARGE_SEARCH_WARNING_DELAY_SECONDS = 10;

const ListItemSchema = z.object({ id: z.string(), label: z.string() });
type ListItemSchemaType = z.infer<typeof ListItemSchema>;

// Map inferred Zod schema to Orval generated class due to type mismatch.
// Ref: https://github.com/colinhacks/zod/issues/43
function toListItem(item?: ListItemSchemaType): ListItem {
    return {
        id: item?.id || "",
        label: item?.label || "",
    };
}

function toListItemArray(items?: ListItemSchemaType[]): ListItem[] {
    return items?.map(toListItem) || [];
}

const PolygonGeometryEnum = z.enum(["Polygon", "MultiPolygon"]);

const FormSchema = z
    .object({
        county: ListItemSchema.nullable(),

        polygons: z
            .array(
                z.object({
                    id: z.string(),
                    type: z.enum(["Feature"]).optional(),
                    properties: z.record(z.string(), z.string()).or(z.null()),
                    geometry: z.object({
                        coordinates: z
                            .array(z.array(z.array(z.array(z.number()))))
                            .or(z.array(z.array(z.array(z.number())))),
                        type: PolygonGeometryEnum,
                    }),
                }),
            )
            .optional(),

        subdivisionList: z.array(z.string()).nullable(),
        zoningTypeList: z.array(z.string()).nullable(),
        landUseCodeList: z.array(ListItemSchema).nullable(),
        acresFrom: POSITIVE_NUMBER_VALIDATOR,
        acresTo: POSITIVE_NUMBER_VALIDATOR,
        improvementPercentage: INTEGER_PERCENT_VALIDATOR,
        excludeHOA: z.boolean().optional(),
        showExcludeZip: z.boolean().optional(),

        // ZipCode logic
        zipCode: US_ZIP_CODE_VALIDATOR, // autocomplete input field
        excludeZipCode: US_ZIP_CODE_VALIDATOR, // autocomplete input field
        includedZipCodes: z.array(z.string()),
        excludeZipCodes: z.array(z.string()),

        // Structure
        aiVacant: z.boolean().optional(),
        minTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
        maxTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
        minTotalStructureCount: POSITIVE_INTEGER_VALIDATOR,
        maxTotalStructureCount: POSITIVE_INTEGER_VALIDATOR,

        // owner
        outOfStateOwner: z.boolean().optional(),
        outOfCountyOwner: z.boolean().optional(),
        outOfZipOwner: z.boolean().optional(),
        excludeCorpOwner: z.boolean().optional(),
        excludeKeyword: z.string().optional().nullable(), // autocomplete input field
        excludeKeywords: z.array(z.string()),
        includeKeyword: z.string().optional().nullable(), // autocomplete input field
        includeKeywords: z.array(z.string()),
        interFamilyFlag: z.boolean().optional(),
        minOwnershipLengthInMonths: POSITIVE_INTEGER_VALIDATOR,
        maxOwnershipLengthInMonths: POSITIVE_INTEGER_VALIDATOR,

        // scrubbing
        removeLandLockedParcels: z.boolean().optional(),
        removeDuplicateParcels: z.boolean().optional(),
        deduplicatingType: z
            .enum([SizePreferenceEnum.smaller, SizePreferenceEnum.larger])
            .optional(),
        maxWetlandCoverage: INTEGER_PERCENT_VALIDATOR,
        maxFloodCoverage: INTEGER_PERCENT_VALIDATOR,
        minRoadFrontage: POSITIVE_INTEGER_VALIDATOR,

        // Skip Tracing
        skipTracingType: z
            .enum([ReportTypeEnum.standard, ReportTypeEnum.premium])
            .nullable()
            .optional(),
        isScrubDnc: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
        if (data.polygons.length > 0) {
            if (!data.acresFrom && !data.acresTo) {
                ctx.addIssue({
                    message: "This field is required",
                    code: z.ZodIssueCode.custom,
                    path: ["acresFrom"],
                });
                ctx.addIssue({
                    message: "This field is required",
                    code: z.ZodIssueCode.custom,
                    path: ["acresTo"],
                });
            }
        }
    });

type FormSchemaType = z.infer<typeof FormSchema>;

const DEFAULT_FORM_VALUES: FormSchemaType = {
    acresFrom: null,
    acresTo: null,
    aiVacant: false,
    county: null,
    deduplicatingType: SizePreferenceEnum.larger,
    excludeCorpOwner: false,
    excludeHOA: false,
    excludeKeywords: [],
    excludeZipCodes: [],
    improvementPercentage: null,
    includeKeywords: [],
    includedZipCodes: [],
    interFamilyFlag: false,
    landUseCodeList: [],
    maxFloodCoverage: null,
    maxOwnershipLengthInMonths: null,
    maxTotalSqFt: null,
    maxTotalStructureCount: null,
    maxWetlandCoverage: null,
    minOwnershipLengthInMonths: null,
    minRoadFrontage: null,
    minTotalSqFt: null,
    minTotalStructureCount: null,
    outOfCountyOwner: false,
    outOfStateOwner: false,
    outOfZipOwner: false,
    polygons: [],
    removeDuplicateParcels: false,
    removeLandLockedParcels: false,
    showExcludeZip: false,
    skipTracingType: null,
    subdivisionList: [],
    zoningTypeList: [],
};

interface MapFilterProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function formatFeature(feature: any) {
    return {
        id: toString(feature.id),
        type: feature.type,
        properties: feature.properties,
        geometry: {
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
        },
    };
}

export function MapFilter({ isOpen, setIsOpen }: MapFilterProps) {
    const { setSearchResult, savedList, setSavedList, isPolygonActive } =
        useMapContext();
    const [isParcelList, setIsParcelListHandler] = useState<boolean>(false);
    const map = useMap();
    const { polygon, SettingTooltip, deletePolygon, syncPolygons, setPolygonActive } =
        usePolygon({
            onError: () => {
                syncPolygons(methods.getValues().polygons as FeaturePolygon[]);
            },
            onCreate: (events) => {
                const updatedPolygons = [...methods.getValues().polygons];
                events.features.forEach((feature) => {
                    if (updatedPolygons.findIndex((e) => e.id === feature.id) > -1) {
                        return;
                    }

                    updatedPolygons.push(formatFeature(feature));
                });

                methods.setValue("polygons", updatedPolygons);

                // Reset the county when a new polygon is created
                methods.setValue("county", null);
            },
            onUpdate(event) {
                let updatedPolygons = [...methods.getValues().polygons];
                event.features.forEach((feature) => {
                    updatedPolygons = updatedPolygons.map((f) =>
                        f.id === feature.id ? formatFeature(feature) : f,
                    );
                });
                methods.setValue("polygons", updatedPolygons);
            },
            onDelete: (events) => {
                let newPolygons = [...methods.getValues().polygons];
                events.features.forEach((feature) => {
                    newPolygons = newPolygons.filter((f) => f.id !== feature?.id);
                });
                methods.setValue("polygons", newPolygons);

                if (newPolygons.length === 0) {
                    setSearchResult(null);
                }
            },
        });

    const defaultValues = useMemo((): FormSchemaType => {
        const searchFilters = savedList?.search_filters as SaveFilterParcelsListParams;
        if (!searchFilters) {
            return DEFAULT_FORM_VALUES;
        }

        const hasPolygons =
            Boolean(searchFilters.polygons) && searchFilters.polygons?.length > 0;

        if (hasPolygons) {
            // @ts-ignore
            map.current?.fitBounds(bbox(first(searchFilters.polygons)), {
                maxZoom: 11,
                padding: 100,
            });
            syncPolygons(searchFilters.polygons);
        }

        // prettier-ignore
        return {
            ...DEFAULT_FORM_VALUES,
            acresFrom: searchFilters.acres_min ?? DEFAULT_FORM_VALUES.acresFrom,
            acresTo: searchFilters.acres_max ?? DEFAULT_FORM_VALUES.acresTo,
            aiVacant: searchFilters.ai_vacant ?? DEFAULT_FORM_VALUES.aiVacant,
            county: searchFilters.countyOption ?? DEFAULT_FORM_VALUES.county,
            excludeCorpOwner: searchFilters.exclude_corp_owner ??  DEFAULT_FORM_VALUES.excludeCorpOwner,
            excludeHOA: searchFilters.exclude_hoa ?? DEFAULT_FORM_VALUES.excludeHOA,
            excludeKeywords: searchFilters.exclude_keywords ?? DEFAULT_FORM_VALUES.excludeKeywords,
            excludeZipCodes: searchFilters.zips_exclude ?? DEFAULT_FORM_VALUES.excludeZipCodes,
            improvementPercentage: searchFilters.improvement_percentage_max ??  DEFAULT_FORM_VALUES.improvementPercentage,
            includeKeywords: searchFilters.include_keywords ?? DEFAULT_FORM_VALUES.includeKeywords,
            includedZipCodes: searchFilters.zips_include ?? DEFAULT_FORM_VALUES.includedZipCodes,
            interFamilyFlag: searchFilters.inter_family_flag ?? DEFAULT_FORM_VALUES.interFamilyFlag,
            landUseCodeList: searchFilters.landUseCodeList ?? DEFAULT_FORM_VALUES.landUseCodeList,
            maxOwnershipLengthInMonths: searchFilters.ownership_length_max ??  DEFAULT_FORM_VALUES.maxOwnershipLengthInMonths,
            maxTotalSqFt: searchFilters.sq_ft_max ?? DEFAULT_FORM_VALUES.maxTotalSqFt,
            maxTotalStructureCount: searchFilters.structure_count_max ??  DEFAULT_FORM_VALUES.maxTotalStructureCount,
            minOwnershipLengthInMonths: searchFilters.ownership_length_min ??  DEFAULT_FORM_VALUES.minOwnershipLengthInMonths,
            minTotalSqFt: searchFilters.sq_ft_min ?? DEFAULT_FORM_VALUES.minTotalSqFt,
            minTotalStructureCount: searchFilters.structure_count_min ??  DEFAULT_FORM_VALUES.minTotalStructureCount,
            outOfCountyOwner: searchFilters.out_of_county_owner ??  DEFAULT_FORM_VALUES.outOfCountyOwner,
            outOfStateOwner: searchFilters.out_of_state_owner ?? DEFAULT_FORM_VALUES.outOfStateOwner,
            outOfZipOwner: searchFilters.out_of_zip_owner ?? DEFAULT_FORM_VALUES.outOfZipOwner,
            polygons: searchFilters.polygons ?? DEFAULT_FORM_VALUES.polygons,
            subdivisionList: searchFilters.subdivision ?? DEFAULT_FORM_VALUES.subdivisionList,
            zoningTypeList: searchFilters.zoning ?? DEFAULT_FORM_VALUES.zoningTypeList,
        };
    }, [map, savedList?.search_filters, syncPolygons]);

    const setIsParcelList = useCallback(
        (value: boolean) => {
            setIsParcelListHandler(value);
            // Disable the polygon drawing when the parcel list is open
            setPolygonActive(!value);
        },
        [setPolygonActive],
    );

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        mode: "onBlur",
        defaultValues,
    });

    const formValues = methods.watch();

    // TODO: update signature after removing countyOption from search_filters
    // const searchFilters = useMemo((): SaveFilterParcelsListParams => {
    const searchFilters = useMemo<SaveFilterParcelsListParams>(() => {
        const parsedForm = FormSchema.safeParse(formValues);
        if (!parsedForm.success) {
            return null;
        }
        const { data } = parsedForm;

        const countySelectedOption = data.county as CountyOption;

        // @ts-ignore
        const combined = combine(featureCollection(data.polygons));

        return {
            geom_intersects:
                isArray(data?.polygons) && Boolean(combined)
                    ? JSON.stringify(combined.features[0]?.geometry)
                    : null,
            county: countySelectedOption?.id || "",
            subdivision: data.subdivisionList,
            zips_include: data.includedZipCodes,
            zips_exclude: data.excludeZipCodes,
            zoning: data.zoningTypeList,
            land_use: isArray(data?.landUseCodeList)
                ? data.landUseCodeList.map((item) => item.id)
                : [],
            acres_min: data.acresFrom,
            acres_max: data.acresTo,
            improvement_percentage_max: data.improvementPercentage,
            exclude_hoa: parseBoolean(data.excludeHOA),
            out_of_state_owner: parseBoolean(data.outOfStateOwner),
            out_of_county_owner: parseBoolean(data.outOfCountyOwner),
            out_of_zip_owner: parseBoolean(data.outOfZipOwner),
            exclude_corp_owner: parseBoolean(data.excludeCorpOwner),
            exclude_keywords: data.excludeKeywords,
            include_keywords: data.includeKeywords,
            inter_family_flag: parseBoolean(data.interFamilyFlag),
            ownership_length_min: data.minOwnershipLengthInMonths,
            ownership_length_max: data.maxOwnershipLengthInMonths,
            ai_vacant: parseBoolean(data.aiVacant),
            sq_ft_min: data.minTotalSqFt,
            sq_ft_max: data.maxTotalSqFt,
            structure_count_min: data.minTotalStructureCount,
            structure_count_max: data.maxTotalStructureCount,

            county_label: countySelectedOption?.label || "",
            countyOption: countySelectedOption,
            landUseCodeList: toListItemArray(data.landUseCodeList),
        };
    }, [formValues]);

    const formState = useMemo((): ParcelExportFormState => {
        const parsedForm = FormSchema.safeParse(formValues);
        if (!parsedForm.success) {
            return null;
        }
        const { data } = parsedForm;
        return {
            acres_from: data.acresFrom,
            acres_to: data.acresTo,
            ai_vacant: data.aiVacant,
            county: Boolean(data.county)
                ? { id: data.county?.id, label: data.county?.label }
                : null,
            deduplicating_type: data.deduplicatingType,
            exclude_corp_owner: data.excludeCorpOwner,
            exclude_hoa: data.excludeHOA,
            exclude_keywords: data.excludeKeywords,
            excluded_zip_codes: data.excludeZipCodes,
            improvement_percentage: data.improvementPercentage,
            include_keywords: data.includeKeywords,
            included_zip_codes: data.includedZipCodes,
            inter_family_flag: data.interFamilyFlag,
            is_scrub_dnc: data.isScrubDnc,
            land_use_code_list: toListItemArray(data.landUseCodeList),
            max_flood_coverage: data.maxFloodCoverage,
            max_ownership_length_in_months: data.maxOwnershipLengthInMonths,
            max_total_sq_ft: data.maxTotalSqFt,
            max_total_structure_count: data.maxTotalStructureCount,
            max_wetland_coverage: data.maxWetlandCoverage,
            min_ownership_length_in_months: data.minOwnershipLengthInMonths,
            min_road_frontage: data.minRoadFrontage,
            min_total_sq_ft: data.minTotalSqFt,
            min_total_structure_count: data.minTotalStructureCount,
            out_of_county_owner: data.outOfCountyOwner,
            out_of_state_owner: data.outOfStateOwner,
            out_of_zip_owner: data.outOfZipOwner,
            polygons: data.polygons,
            remove_duplicate_parcels: data.removeDuplicateParcels,
            remove_land_locked_parcels: data.removeLandLockedParcels,
            skip_tracing_type: data.skipTracingType,
            subdivision_list: data.subdivisionList,
            zoning_type_list: data.zoningTypeList,
        };
    }, [formValues]);

    function resetForm() {
        // Remove all polygons from the map
        formValues.polygons.forEach((feature) => {
            // @ts-ignore
            deletePolygon([feature]);
        });

        // Reset the search result on the map
        setSavedList(null);
        setSearchResult(null);

        // Reset the form
        methods.reset(DEFAULT_FORM_VALUES);
    }

    useEffect(() => {
        if (!isOpen && !isParcelList) {
            setPolygonActive(false);
        }

        if (isOpen && !isParcelList) {
            setPolygonActive(true);
        }
    }, [isOpen, isParcelList, setPolygonActive]);

    return (
        <FormProvider {...methods}>
            <form>
                <Sidebar
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    removeBackdrop
                    onClose={() => {
                        setSearchResult(null);
                        setSavedList({
                            id: "temp-search-filter",
                            title: "temp-search-filter",
                            form_state: null,
                            search_filters: searchFilters,
                            created_at: null,
                            updated_at: null,
                        });
                    }}
                    className={clsx({
                        "lui-cursor-crosshair": isPolygonActive,
                    })}
                >
                    <ErrorBoundary
                        onReset={() => {
                            // If the error boundary originated from the export list, return the user to the form;
                            //  otherwise, reset the form
                            if (isParcelList) {
                                setIsParcelList(false);
                            } else {
                                resetForm();
                            }
                        }}
                    >
                        {SettingTooltip}

                        {/* Export parce list menu  */}
                        {isParcelList ? (
                            <MapFilterParcel
                                formState={formState}
                                searchFilters={searchFilters}
                                setIsParcelList={setIsParcelList}
                                // TODO: once we add strict types in typescript we won't need to cast this
                                countyOption={methods.watch("county") as CountyOption}
                                polygons={methods.watch("polygons") as FeaturePolygon[]}
                                resetFilterForm={resetForm}
                                scrubDuplicates={formValues.removeDuplicateParcels}
                                sizePreference={formValues.deduplicatingType}
                                landScrubs={{
                                    flood_zone_allowed: parseNumber(
                                        formValues.maxFloodCoverage,
                                    ),
                                    wetlands_allowed: parseNumber(
                                        formValues.maxWetlandCoverage,
                                    ),
                                    road_frontage: parseNumber(
                                        formValues.minRoadFrontage,
                                    ),
                                    land_locked: formValues.removeLandLockedParcels,
                                    created_at: null,
                                    updated_at: null,
                                }}
                                skipTrace={{
                                    scrub_dnc: formValues.isScrubDnc,
                                    report_type: formValues.skipTracingType,
                                    created_at: null,
                                    updated_at: null,
                                }}
                            />
                        ) : (
                            <>
                                <Sidebar.Header preventAutoFocus>
                                    Filter Parcels
                                </Sidebar.Header>
                                <Sidebar.Content>
                                    <PolygonAccordion
                                        polygon={polygon}
                                        deletePolygon={deletePolygon}
                                    />
                                    <ParcelFormAccordion />
                                    <OwnerFormAccordion />
                                    <StructureFormAccordion />
                                    <ScrubbingFormAccordion />
                                    <SkipTracingFormAccordion />
                                </Sidebar.Content>
                                <FormFooter
                                    searchFilters={searchFilters}
                                    setIsParcelList={setIsParcelList}
                                    resetForm={resetForm}
                                    setPolygonActive={setPolygonActive}
                                />
                            </>
                        )}
                    </ErrorBoundary>
                </Sidebar>
            </form>
        </FormProvider>
    );
}

interface PolygonAccordionProps {
    polygon: MapboxDraw;
    deletePolygon: (id: FeaturePolygon[]) => void;
}

function PolygonAccordion({ polygon, deletePolygon }: PolygonAccordionProps) {
    const { isPolygonActive } = useMapContext();
    const map = useMap();
    const { watch } = useFormContext<FormSchemaType>();
    const polygons = watch("polygons");

    return (
        <div className="lui-p-6 lui-pb-0">
            <div className="lui-flex lui-justify-between lui-items-center">
                <Typography variant="h6" size="lg" weight="bold">
                    Polygons
                </Typography>
                <Button
                    variant="base"
                    onClick={() => {
                        polygon.changeMode("draw_polygon");
                    }}
                >
                    <span
                        className={clsx({
                            "lui-cursor-crosshair": isPolygonActive,
                        })}
                    >
                        <Icon name="PenPlus" color="primary-500" />{" "}
                        <Typography color="primary-500" variant="span" weight="medium">
                            Add zone
                        </Typography>
                    </span>
                </Button>
            </div>
            <div className="lui-mt-2 lui-flex lui-flex-col lui-gap-2">
                {polygons.map((feature, index) => {
                    return (
                        <div
                            key={index}
                            className="lui-flex lui-gap-3 lui-items-center lui-justify-between"
                        >
                            <div className="lui-flex lui-gap-2 lui-items-center">
                                <Icon name="PenPlus" color="primary-500" />
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        // @ts-ignore
                                        map.current?.fitBounds(bbox(feature), {
                                            maxZoom: 11,
                                            padding: 100,
                                        });
                                    }}
                                >
                                    <Typography>Zone {index + 1}</Typography>
                                </Button>
                            </div>
                            <Button
                                variant="base"
                                onClick={() => {
                                    // @ts-ignore
                                    deletePolygon([feature]);
                                }}
                                icon="Trash"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function FormFooter({
    setIsParcelList,
    searchFilters,
    resetForm,
    setPolygonActive,
}: {
    setIsParcelList: (value: boolean) => void;
    searchFilters: ParcelsListParams;
    resetForm: () => void;
    setPolygonActive: (value: boolean) => void;
}) {
    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: LARGE_SEARCH_WARNING_DELAY_SECONDS,
    });

    const { isOpen } = useSidebar();
    const {
        handleSubmit,
        formState: { errors, isValid },
    } = useFormContext<FormSchemaType>();
    const [showErrorBox, setShowErrorBox] = useState(true);
    const { searchResult, setSearchResult } = useMapContext();
    const {
        isLoading,
        refetch: refetchParcelSearch,
        error,
    } = useParcelsSearchRetrieve(searchFilters, {
        query: {
            // Disable this query and only trigger once its refetched
            enabled: false,
            retry: false,
        },
    });

    const refetch = useCallback(
        async (
            startCountdown: () => void,
            resetCountdown: () => void,
            refetchParcelSearch: () => Promise<
                QueryObserverResult<ParcelSearchResponse, unknown>
            >,
            setSearchResult: (
                value: ParcelSearchResponse,
                options?: SearchResultOptions,
            ) => void,
        ) => {
            startCountdown();
            setPolygonActive(false);
            const newSearchResult = await refetchParcelSearch();
            setPolygonActive(true);
            resetCountdown();
            if (newSearchResult) {
                setSearchResult(newSearchResult.data, {
                    disableZoom: true,
                });
            }
        },
        [setPolygonActive],
    );

    const debouncedRefetch = useDebouncedCallback(refetch, 500);

    const [prevSearchFilters, setPrevSearchFilters] = useState<ParcelsListParams>();

    // Refetch the data when searchFilters change
    useEffect(() => {
        if (!isValid || isLoading) {
            return;
        }
        if (
            isOpen &&
            Boolean(searchFilters) &&
            JSON.stringify(searchFilters) !== JSON.stringify(prevSearchFilters) &&
            (searchFilters.geom_intersects?.length > 0 ||
                searchFilters.county?.length > 0)
        ) {
            // TODO: move these checks to form validation
            if (
                searchFilters.acres_min ||
                searchFilters.acres_max ||
                searchFilters.subdivision?.length > 0 ||
                searchFilters.zips_include?.length > 0 ||
                searchFilters.zips_exclude?.length > 0
            ) {
                debouncedRefetch(
                    startCountdown,
                    resetCountdown,
                    refetchParcelSearch,
                    setSearchResult,
                );
                setPrevSearchFilters(searchFilters);
            }
        }
    }, [
        searchFilters,
        prevSearchFilters,
        setPrevSearchFilters,
        debouncedRefetch,
        isLoading,
        isOpen,
        isValid,
        refetchParcelSearch,
        resetCountdown,
        setSearchResult,
        startCountdown,
    ]);

    const hasErrors = Object.keys(errors).length > 0;

    /**
     * Show the error box when there are errors since its dismissible
     */
    useEffect(() => {
        if (hasErrors) {
            setShowErrorBox(true);
        }
    }, [errors, hasErrors]);

    const onSubmit = async () => {
        setIsParcelList(true);
    };

    return (
        <Sidebar.Footer fluid className="lui-p-0">
            {hasErrors && showErrorBox && (
                <div className="lui-border lui-bg-red-200  lui-rounded-lg lui-p-6">
                    <div className="lui-flex lui-gap-2">
                        <Typography>
                            <Icon name="InfoCircle" size="sm" color="red-700" />
                        </Typography>
                        <Typography size="sm" weight="medium">
                            Some fields are missing or contain errors. Please review the
                            highlighted fields.
                        </Typography>
                        <Button
                            variant="base"
                            icon="Close"
                            onClick={() => setShowErrorBox(false)}
                        />
                    </div>
                    <ul className="list-disc lui-mt-2.5">
                        {Object.keys(errors).map((key) => {
                            return (
                                <Typography
                                    key={key}
                                    size="sm"
                                    variant="li"
                                    color="black"
                                    className="lui-mt-1"
                                >
                                    <span className="lui-border-y-0 lui-border-x-0 lui-border-b lui-border-black lui-border-solid">
                                        {get(fieldKeyToTitle, key, key)}
                                    </span>{" "}
                                    {get(errors, `${key}.message`)}
                                </Typography>
                            );
                        })}
                    </ul>
                </div>
            )}

            {count === 0 && (
                <WarningMessage message="Seems like you made a large search. Hang tight while we crunch the numbers." />
            )}

            {searchResult?.more_results && !isLoading && (
                <WarningMessage message="Your search results were cut short. We suggest adding more filters to see the full list of parcels." />
            )}

            {error && (
                <div className="lui-bg-red-200 lui-border-l-4 lui-border-orange-500  lui-p-4">
                    <div className="lui-flex lui-gap-2">
                        <Icon name="InfoCircle" size="sm" color="red-900" />
                        <Typography size="sm" weight="medium" color="red-900">
                            Api query failed, please try again later.
                        </Typography>
                    </div>
                </div>
            )}

            <div className="lui-flex lui-gap-4 lui-justify-between lui-p-6">
                <Button className="" variant="inline" onClick={resetForm}>
                    Clear filter
                </Button>
                <div className=" lui-flex lui-justify-end">
                    <Button
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={
                            isLoading ||
                            (searchResult?.results &&
                                searchResult?.results?.length === 0) ||
                            searchResult?.more_results
                        }
                        isLoading={isLoading}
                        className="lui-min-w-40"
                    >
                        {!searchResult && "Filter Parcels"}
                        {searchResult?.results?.length >= 0 &&
                            `Export ${formatNumber(searchResult?.count)} Parcels`}
                    </Button>
                </div>
            </div>
        </Sidebar.Footer>
    );
}

function WarningMessage({ message }: { message: string }) {
    return (
        <div className="lui-bg-orange-100 lui-border-l-4 lui-border-orange-500  lui-p-4">
            <div className="lui-flex lui-gap-2">
                <Icon name="InfoCircle" size="sm" color="orange-900" />
                <Typography size="sm" weight="medium" color="orange-900">
                    {message}{" "}
                </Typography>
            </div>
        </div>
    );
}

interface CountyOptionData {
    isMapboxFeature: boolean;
}

function useAutocompleteRetrieveLazy(params: AutocompleteRetrieveParams) {
    const queryClient = useQueryClient();
    return () =>
        queryClient.ensureQueryData({
            queryKey: ["autocomplete", params.county, params.field, params.query],
            queryFn: () => autocompleteRetrieve(params),
        });
}

function useAutocompleteLookupRetrieveLazy(params: AutocompleteLookupRetrieveParams) {
    const queryClient = useQueryClient();
    return () =>
        queryClient.ensureQueryData({
            queryKey: [
                "autocomplete-lookup",
                params.county,
                params.field,
                params.query,
            ],
            queryFn: () => autocompleteLookupRetrieve(params),
        });
}

function ParcelFormAccordion() {
    const {
        watch,
        register,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(true);
    const { current: map } = useMap();
    const { setMapFilter, setSearchResult } = useContext(ParcelViewerContext);

    const countyAutocompleteProps = useAutocomplete<CountyOptionData>({
        id: "county-search-autocomplete",
        placeholder: "Enter county name...",
        label: "County",
        error: errors.county?.message,
        disabled: watch("polygons")?.length > 0,
        // Remove all of these "as CountyOption" once we have strictNullChecks enabled in this project
        selectedOption: watch("county") as CountyOption,
        initialOptions: (watch("county") as CountyOption)
            ? [watch("county") as CountyOption]
            : [],
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                setValue("county", null);
                setSearchResult(null);
                subdivisionAutocompleteProps.clear();
                return;
            }

            if (!selectedItem.data?.isMapboxFeature) {
                return;
            }

            const featureColl = await fetchMapboxFeature(selectedItem.id);
            const feature = featureColl.features[0];

            if (feature) {
                const result = await findFeatureOnMap(map, feature, 400);

                if (result && result.mapFeature) {
                    const { identifyLayer, idField, mapFeature } = result;

                    // Filter layer by feature ID
                    const newFilter = ["==", idField, mapFeature.id];
                    const inverseFilter = ["!", newFilter];

                    // Apply map filter for shadow layer
                    setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                    const countyOption = {
                        id: mapFeature.properties.fips,
                        label: selectedItem.label,
                    };

                    setValue("county", countyOption);
                    trigger("county"); // Trigger validation
                }
            }
        },
        onSearch: async (inputValue) => {
            if (!inputValue) {
                return [];
            }
            const data = await searchMapbox(inputValue, "district");
            return (
                data?.map((e) => ({
                    id: e.mapbox_id,
                    label: `${e.name}, ${e.context.region.region_code}`,
                    data: {
                        isMapboxFeature: true,
                    },
                })) ?? []
            );
        },
        onReset: () => {
            subdivisionAutocompleteProps.onReset();
        },
    });

    const county = getValues("county");
    const fetchSubdivisions = useAutocompleteRetrieveLazy({
        county: county?.id,
        field: "SubdivisionName",
        query: "",
    });
    const fetchZoningTypes = useAutocompleteRetrieveLazy({
        county: county?.id,
        field: "Zoning",
        query: "",
    });
    const fetchLandUseCodes = useAutocompleteLookupRetrieveLazy({
        county: county?.id,
        field: "LandUseCode",
        query: "",
    });

    const subdivisionAutocompleteProps = useAutocomplete<void>({
        id: "subdivision-search-autocomplete",
        placeholder: "Enter subdivision name...",
        label: "Subdivision",
        onSelect: async (selectedItem) => {
            setValue("subdivisionList", [...subdivisionList, selectedItem.id]);
            subdivisionAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            if (!county) {
                return [];
            }
            const response = await fetchSubdivisions();
            const searchTerm = query.toLowerCase();
            return response.data
                .map((str) => ({ id: str, label: str }))
                .filter((item) => !subdivisionList.includes(item.label))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        disabled: !getValues("county"),
        debounceDelay: 1,
    });

    const zoningTypeAutocompleteProps = useAutocomplete({
        id: "zoning-search-autocomplete",
        placeholder: "Enter zoning type...",
        label: "Zoning Type",
        onSelect: async (selectedItem) => {
            setValue("zoningTypeList", [...zoningTypeList, selectedItem.id]);
            zoningTypeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const county = getValues("county");
            if (!county) {
                return [];
            }
            const response = await fetchZoningTypes();
            const searchTerm = query.toLowerCase();
            return response.data
                .map((str) => ({ id: str, label: str }))
                .filter((item) => !zoningTypeList.includes(item.label))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        disabled: !getValues("county"),
        debounceDelay: 1,
    });

    const landUseCodeAutocompleteProps = useAutocomplete({
        id: "landuse-search-autocomplete",
        placeholder: "Enter land use code...",
        label: "Land Use",
        onSelect: async (selectedItem) => {
            setValue("landUseCodeList", [...landUseCodeList, selectedItem]);
            landUseCodeAutocompleteProps.clear();
        },
        onSearch: async (query) => {
            const county = getValues("county");
            if (!county) {
                return [];
            }
            const response = await fetchLandUseCodes();
            const searchTerm = query.toLowerCase();
            return response.data
                .filter((a) => !landUseCodeList.some((b) => a.id === b.id))
                .filter((item) => item.label.toLowerCase().includes(searchTerm));
        },
        disabled: !getValues("county"),
        debounceDelay: 1,
    });

    const showExcludeZip = watch("showExcludeZip");
    const includedZipCodes = watch("includedZipCodes", []);
    const excludeZipCodes = watch("excludeZipCodes", []);
    const subdivisionList = watch("subdivisionList", []);
    const zoningTypeList = watch("zoningTypeList", []);
    const landUseCodeList = watch("landUseCodeList", []);

    const isError = hasErrorByKeys(errors, [
        "county",
        "zipCode",
        "excludeZipCode",
        "acresFrom",
        "acresTo",
        "improvementPercentage",
    ]);
    const excludeZipCodeRegister = register("excludeZipCode");

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Parcel" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Autocomplete {...countyAutocompleteProps} autoFocus />

                <div>
                    <Autocomplete {...subdivisionAutocompleteProps} />
                    <TagList
                        items={subdivisionList}
                        onRemove={(item) => {
                            setValue(
                                "subdivisionList",
                                subdivisionList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="zipCode"
                        placeholder="Search ZIP..."
                        label="Include ZIPs"
                        action={
                            showExcludeZip ? null : (
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        setValue("showExcludeZip", true);
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        color="primary-500"
                                        weight="medium"
                                    >
                                        Exclude ZIPs
                                    </Typography>
                                </Button>
                            )
                        }
                        error={errors.zipCode?.message.toString()}
                        onEnter={async (value) => {
                            const isValid = await trigger("zipCode");
                            if (!isValid || !value) return;
                            if (includedZipCodes.includes(value)) {
                                // Don't add duplicates
                                setValue("zipCode", null);
                            } else {
                                // Add the zip code to the list
                                setValue("includedZipCodes", [
                                    ...includedZipCodes,
                                    value,
                                ]);
                                setValue("zipCode", null);
                            }
                        }}
                        {...register("zipCode")}
                    />

                    <TagList
                        items={includedZipCodes}
                        onRemove={(item) => {
                            setValue(
                                "includedZipCodes",
                                includedZipCodes.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                {showExcludeZip && (
                    <div>
                        <Input
                            id="excludeZipCode"
                            placeholder="Search ZIP..."
                            label="Exclude ZIPs"
                            error={errors.excludeZipCode?.message.toString()}
                            onEnter={async (value) => {
                                const isValid = await trigger("excludeZipCode");
                                if (!isValid || !value) return;
                                if (excludeZipCodes.includes(value)) {
                                    // Don't add duplicates
                                    setValue("excludeZipCode", null);
                                } else {
                                    // Add the zip code to the list
                                    setValue("excludeZipCodes", [
                                        ...excludeZipCodes,
                                        value,
                                    ]);
                                    setValue("excludeZipCode", null);
                                }
                            }}
                            // Autofocus as soon as it shows up
                            autoFocus
                            {...excludeZipCodeRegister}
                        />

                        <TagList
                            items={excludeZipCodes}
                            onRemove={(item) => {
                                setValue(
                                    "excludeZipCodes",
                                    excludeZipCodes.filter((i) => i !== item),
                                );
                            }}
                        />
                    </div>
                )}
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="acresFrom"
                        label={fieldKeyToTitle.acresFrom}
                        placeholder="From"
                        error={errors.acresFrom?.message}
                        {...register("acresFrom", {
                            deps: ["acresTo"],
                        })}
                    />
                    <Input
                        id="acresTo"
                        placeholder="To"
                        srLabel="Acres To"
                        error={errors.acresTo?.message}
                        {...register("acresTo", {
                            deps: ["acresFrom"],
                        })}
                    />
                </div>

                <Input
                    id="improvementPercentage"
                    label={fieldKeyToTitle.improvementPercentage}
                    placeholder="%"
                    error={errors.improvementPercentage?.message}
                    {...register("improvementPercentage")}
                />

                <div>
                    <Autocomplete {...zoningTypeAutocompleteProps} />
                    <TagList
                        items={zoningTypeList}
                        onRemove={(item) => {
                            setValue(
                                "zoningTypeList",
                                zoningTypeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Autocomplete {...landUseCodeAutocompleteProps} />
                    <TagList
                        items={landUseCodeList}
                        formatItem={(item) => item.label}
                        onRemove={(item) => {
                            setValue(
                                "landUseCodeList",
                                landUseCodeList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function StructureFormAccordion() {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);

    const isError = Boolean(
        errors.minTotalSqFt?.message ||
            errors.minTotalStructureCount?.message ||
            errors.maxTotalSqFt?.message ||
            errors.maxTotalStructureCount?.message ||
            errors.aiVacant?.message,
    );

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Structure" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-gap-6">
                    <Toggle
                        id="aiVacant"
                        labelClassName={"lui-typography-medium"}
                        label={fieldKeyToTitle.aiVacant}
                        info="Remove parcels that have AI detected buildings."
                        {...register("aiVacant")}
                    />
                </div>
                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.totalSqFt}
                    </Typography>

                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="totalSqFt"
                            error={errors.minTotalSqFt?.message}
                            placeholder="Min"
                            info="County assessor data"
                            {...register("minTotalSqFt")}
                        />

                        <Input
                            id="totalSqFt"
                            error={errors.maxTotalSqFt?.message}
                            placeholder="Max"
                            {...register("maxTotalSqFt")}
                        />
                    </div>
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="totalStructureCount"
                        label={fieldKeyToTitle.totalStructureCount}
                        error={errors.minTotalStructureCount?.message}
                        placeholder="Min"
                        info="County assessor data"
                        {...register("minTotalStructureCount")}
                    />
                    <Input
                        id="totalStructureCount"
                        srLabel={fieldKeyToTitle.maxTotalStructureCount}
                        error={errors.maxTotalStructureCount?.message}
                        placeholder="Max"
                        {...register("maxTotalStructureCount")}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function OwnerFormAccordion() {
    const {
        register,
        formState: { errors },
        setValue,
        trigger,
        watch,
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const excludeKeywords = watch("excludeKeywords", []).map((x) => x.toUpperCase());
    const includeKeywords = watch("includeKeywords", []).map((x) => x.toUpperCase());

    const isError = hasErrorByKeys(errors, [
        "outOfStateOwner",
        "outOfCountyOwner",
        "outOfZipOwner",
        "minOwnershipLengthInMonths",
        "maxOwnershipLengthInMonths",
        "excludeKeyword",
        "includeKeyword",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Owner" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="outOfStateOwner"
                    label={fieldKeyToTitle.outOfStateOwner}
                    {...register("outOfStateOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfCountyOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfCountyOwner"
                    label={fieldKeyToTitle.outOfCountyOwner}
                    {...register("outOfCountyOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfZipOwner"
                    label={fieldKeyToTitle.outOfZipOwner}
                    {...register("outOfZipOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfCountyOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="excludeCorpOwner"
                    label={fieldKeyToTitle.excludeCorpOwner}
                    {...register("excludeCorpOwner")}
                />
                <Toggle
                    id="interFamilyFlag"
                    label={fieldKeyToTitle.interFamilyFlag}
                    {...register("interFamilyFlag")}
                />

                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.ownershipLengthInMonths}{" "}
                        <Typography variant="span" color="gray-700">
                            (Months)
                        </Typography>
                    </Typography>
                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="minOwnershipLengthInMonths"
                            placeholder="Min"
                            {...register("minOwnershipLengthInMonths")}
                        />
                        <Input
                            id="maxOwnershipLengthInMonths"
                            placeholder="Max"
                            {...register("maxOwnershipLengthInMonths")}
                        />
                    </div>
                </div>

                <div>
                    <Input
                        id="includeKeywords"
                        placeholder="Include keywords..."
                        label="Only Include Keywords"
                        error={errors.excludeKeyword?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("includeKeyword");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!includeKeywords.includes(keyword)) {
                                    includeKeywords.push(keyword);
                                }
                            });
                            setValue("includeKeywords", includeKeywords);
                            setValue("includeKeyword", null);
                        }}
                        {...register("includeKeyword")}
                    />

                    <TagList
                        items={includeKeywords}
                        onRemove={(item) => {
                            setValue(
                                "includeKeywords",
                                includeKeywords.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="excludeKeywords"
                        placeholder="Exclude keywords..."
                        label="Exclude Keywords"
                        error={errors.excludeKeyword?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("excludeKeyword");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!excludeKeywords.includes(keyword)) {
                                    excludeKeywords.push(keyword);
                                }
                            });
                            setValue("excludeKeywords", excludeKeywords);
                            setValue("excludeKeyword", null);
                        }}
                        {...register("excludeKeyword")}
                    />

                    <TagList
                        items={excludeKeywords}
                        onRemove={(item) => {
                            setValue(
                                "excludeKeywords",
                                excludeKeywords.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function ScrubbingFormAccordion() {
    const { user } = useContext(UserContext);
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const isError = hasErrorByKeys(errors, [
        "removeLandLockedParcels",
        "removeDuplicateParcels",
        "deduplicatingType",
        "maxWetlandCoverage",
        "maxFloodCoverage",
        "minRoadFrontage",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Scrubbing" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="excludeHOA"
                    label={fieldKeyToTitle.excludeHOA}
                    {...register("excludeHOA")}
                />
                <Toggle
                    label={fieldKeyToTitle.removeLandLockedParcels}
                    {...register("removeLandLockedParcels")}
                />
                <Toggle
                    label={fieldKeyToTitle.removeDuplicateParcels}
                    {...register("removeDuplicateParcels")}
                />
                <SelectionGroupFormController
                    control={control}
                    label={fieldKeyToTitle.deduplicatingType}
                    name="deduplicatingType"
                    options={[
                        { title: "Smaller Parcel", id: SizePreferenceEnum.smaller },
                        { title: "Larger Parcel", id: SizePreferenceEnum.larger },
                    ]}
                    horizontal
                />
                <Input
                    id="maxWetlandCoverage"
                    label={fieldKeyToTitle.maxWetlandCoverage}
                    placeholder="%"
                    info="2 credits / record. Empty % will not scrub."
                    error={errors.maxWetlandCoverage?.message}
                    {...register("maxWetlandCoverage")}
                />
                <Input
                    id="maxFloodCoverage"
                    label={fieldKeyToTitle.maxFloodCoverage}
                    placeholder="%"
                    info="2 credits / record. Empty % will not scrub."
                    error={errors.maxFloodCoverage?.message}
                    {...register("maxFloodCoverage")}
                />
                {user?.is_staff ? (
                    <Input
                        id="minRoadFrontage"
                        label={
                            <>
                                {fieldKeyToTitle.minRoadFrontage}{" "}
                                <Badge variant="info">STAFF ONLY</Badge>
                            </>
                        }
                        placeholder=""
                        info="Minimum Road Frontage in feet."
                        error={errors.minRoadFrontage?.message}
                        {...register("minRoadFrontage")}
                    />
                ) : (
                    <Typography className="lui-flex lui-gap-2" weight="medium">
                        Road Frontage
                        <Badge variant="danger">COMING SOON</Badge>
                    </Typography>
                )}
            </div>
        </Accordion>
    );
}

function SkipTracingFormAccordion() {
    const [isOpened, setIsOpened] = useState(false);
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const isError = hasErrorByKeys(errors, ["skipTracingType"]);

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Skip Tracing" isError={isError} />}
        >
            <SelectionGroupFormController
                control={control}
                label={fieldKeyToTitle.skipTracingType}
                name="skipTracingType"
                options={[
                    {
                        id: null,
                        title: "No Skipping",
                        subtitle: "FREE",
                    },
                    {
                        id: ReportTypeEnum.standard,
                        title: "Standard",
                        subtitle: "5 Credits / Hit",
                        info: "Includes up to 6 phone numbers and the type of each phone number (mobile, landline, VOIP).",
                    },
                    {
                        id: ReportTypeEnum.premium,
                        title: "Premium",
                        subtitle: "6 Credits / Hit",
                        info: "Includes up to 7 phone numbers, the type of each phone number (mobile, landline, VOIP), age of owner, owner email, relative phone numbers and emails, and higher hit rate (usually > 95%).",
                        children: (
                            <div className="lui-flex lui-justify-between lui-items-center lui-w-full">
                                <div>
                                    <Typography weight="medium" size="xs">
                                        Scrub DNC
                                    </Typography>
                                    <Typography size="xs" color="gray-700">
                                        Remove Do Not Contact. 1 credit / record.
                                    </Typography>
                                </div>
                                <div>
                                    <Toggle {...register("isScrubDnc")} />
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </Accordion>
    );
}

function AccordionFormTitle({ title, isError }: { title: string; isError?: boolean }) {
    return (
        <div className="lui-flex lui-items-center lui-gap-1.5">
            <Typography size="lg" weight="bold">
                {title}
            </Typography>
            {isError && (
                <Icon
                    name="InfoCircle"
                    size="sm"
                    color="red-700"
                    className="lui-mr-1"
                />
            )}
        </div>
    );
}

/**
 * Check if there is an error in the form state by list of keys
 * Used to check the section so we can identify which accordion has an error
 */
function hasErrorByKeys(errors: Record<string, any>, keys: string[]): boolean {
    return keys.some((key) => get(errors, `${key}.message`));
}

//  Map field key to title
const fieldKeyToTitle = {
    county: "County",
    zipCode: "ZIP Code",
    excludeZipCode: "Exclude ZIP Code",
    acresFrom: "Acres Range",
    acresTo: "Acres To",
    improvementPercentage: "Max Improvement Percentage",
    ownershipLengthInMonths: "Ownership Length",
    minOwnershipLengthInMonths: "Min Ownership Length",
    maxOwnershipLengthInMonths: "Max Ownership Length",
    aiVacant: "Vacant Land Only (AI)",
    totalSqFt: "Total Structure Square Feet",
    minTotalSqFt: "Min Total Structure Footprint",
    maxTotalSqFt: "Max Total Structure Footprint",
    totalStructureCount: "Total Structure Count",
    minTotalStructureCount: "Min Structure Count",
    maxTotalStructureCount: "Max Structure Count",
    outOfStateOwner: "Out of State Owner",
    outOfCountyOwner: "Out of County Owner",
    outOfZipOwner: "Out of ZIP Owner",
    excludeCorpOwner: "Exclude Corporate Owners",
    interFamilyFlag: "Only Inter-Family Transfers",
    excludeHOA: "Remove HOA Parcels",
    removeLandLockedParcels: "Remove Land Locked Parcels",
    removeDuplicateParcels: "Deduplicate Owners",
    deduplicatingType: "Keep Which Parcel When Deduplicating",
    maxWetlandCoverage: "Max Wetland Coverage",
    maxFloodCoverage: "Max Flood Coverage",
    minRoadFrontage: "Road Frontage",
    skipTracingType: "Skip Tracing Type",
};

function isNullOrEmpty(val: any): boolean {
    return val === null || val === undefined || val.trim() === "";
}

// Argument may be null if loaded from DEFAULT_FORM_VALUES. Argument may be
// undefined if loading a sparsely populated saved filters object.
function parseNumber(val: any): number | null {
    return isNullOrEmpty(val) ? null : Number(val);
}

// Return null for non-truthy values to disable search filters. This should
// only be necessary for generating the search filters payload.
function parseBoolean(val: any): boolean | null {
    return Boolean(val) ? true : null;
}
