/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Land Insights API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `larger` - Larger
* `smaller` - Smaller
 */
export type SizePreferenceEnum = typeof SizePreferenceEnum[keyof typeof SizePreferenceEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SizePreferenceEnum = {
  larger: 'larger',
  smaller: 'smaller',
} as const;
