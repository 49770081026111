import React, { Component } from "react";

import { toast } from "react-toastify";

import { API_URL } from "settings";
import { LAND_SCRUBBING_ROW_FAILURE } from "constants";
import { UserContext } from "context";
import { Button, Loading } from "library";
import {
    fetch,
    formatMoneyCents,
    isImpersonationActive,
    parseErrorResponse,
    post,
} from "functions";
import { withParams } from "hooks";
import { AGGrid, ParcelMap } from "components";

const BADGE_STYLES = {
    good: {
        backgroundImage: "linear-gradient(310deg, #17AD37, #98EC2D)",
        border: "thin solid #8ee895",
        color: "white",
    },
    bad: {
        backgroundImage: "linear-gradient(310deg, #EA0606, #FF667C)",
        border: "thin solid #ff8f8f",
        color: "white",
    },
    unknown: {
        backgroundImage: "linear-gradient(310deg, #627594, #A8B8D8)",
        border: "thin solid #a9a9a9",
        color: "white",
    },
    highlight: {
        backgroundImage: "linear-gradient(310deg,#7928ca,#ff0080)",
        border: "thin solid #c814a0",
        color: "white",
    },
};

const BAD_FILTERS = {
    "Bad Property": {
        filterType: "text",
        operator: "OR",
        condition1: {
            filterType: "text",
            type: "equals",
            filter: "Yes",
        },
        condition2: {
            filterType: "text",
            type: "equals",
            filter: "Unknown",
        },
    },
};

const MAILER_COST = 57;

function AlterButton({ label, value, disabled, special, onClick, hotkey }) {
    let style = "";
    if (special) {
        style = value ? "highlight" : "unknown";
    } else {
        style = value === null ? "unknown" : value ? "bad" : "good";
    }
    return (
        <div className="alter-btn">
            <Button
                style={{
                    borderRadius: "100px",
                    padding: "0px",
                    margin: "0px",
                    width: "45px",
                    height: "45px",
                    fontSize: "25px",
                    ...BADGE_STYLES[style],
                }}
                disabled={disabled}
                onClick={onClick}
            >
                {value === null ? "?" : value ? "Y" : "N"}
            </Button>
            <div style={{ whiteSpace: "nowrap" }}>{label}</div>
            <div
                style={{ fontSize: ".6rem", fontWeight: 700 }}
            >{`Press ${hotkey}`}</div>
        </div>
    );
}

class LandScrubbingResult extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            request: null,
            output_rows: [],
            output_headers: [],

            loaded: false,
            results_review_index: 0,
            total_bad_items: 0,
            filtered_rows: [],
            show_result_column: false,
            current_filter: "all",
        };

        this.move_next = this.move_next.bind(this);
        this.move_prev = this.move_prev.bind(this);
        this.handle_keypress = this.handle_keypress.bind(this);
        this.toggle_row = this.toggle_row.bind(this);
        this.alter_row = this.alter_row.bind(this);
        this.mark_row_viewed = this.mark_row_viewed.bind(this);
        this.update_rows = this.update_rows.bind(this);
        this.update_filtered_rows = this.update_filtered_rows.bind(this);
        this.highlight_result = this.highlight_result.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handle_keypress, true);

        const { id } = this.props.params;

        fetch(`/api/analytics/scrubbing_results/${id}/`)
            .then((data) => {
                const { request, output_headers, output_rows, last_viewed } = data;

                const show_result_column = output_rows.some(
                    (row) => row.status === LAND_SCRUBBING_ROW_FAILURE,
                );

                this.setState(
                    {
                        loaded: true,
                        request,
                        output_headers,
                        output_rows,
                        show_result_column,
                    },
                    this.update_filtered_rows,
                );

                // Start from last viewed row
                const rowIndex = output_rows.findIndex((row) => row.id === last_viewed);
                if (rowIndex !== -1) {
                    this.setState({
                        results_review_index: rowIndex,
                    });
                }

                // Mark first row as viewed on load
                if (!last_viewed) {
                    this.mark_row_viewed(output_rows[0]);
                }
            })
            .catch((xhr) => {
                toast.error(parseErrorResponse(xhr));
            });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handle_keypress, true);
    }

    handle_keypress(e) {
        if (this.state.altering) {
            return;
        }

        const shortcuts = {
            KeyD: this.move_next,
            ArrowRight: this.move_next,
            KeyA: this.move_prev,
            ArrowLeft: this.move_prev,
            1: () => this.toggle_row("land_locked"),
            2: () => this.toggle_row("wetlands"),
            3: () => this.toggle_row("flood_zone"),
            4: () => this.toggle_row("bad_topography"),
            5: () => this.toggle_row("odd_shape"),
            6: () => this.toggle_row("structure"),
            7: () => this.toggle_row("farmland"),
            8: () => this.toggle_row("subdivide"),
            9: () => this.toggle_row("entitlement"),
        };

        const cleaned_hotkey_code = e.code.replace("Digit", "").replace("Numpad", "");

        const action = shortcuts[cleaned_hotkey_code];
        if (action) {
            e.stopPropagation();
            e.preventDefault();
            action();
        }
    }

    toggle_row(field) {
        const current_row = this.state.filtered_rows[this.state.results_review_index];
        if (current_row) {
            this.alter_row(current_row, field);
        }
    }

    alter_row(result, field) {
        const data = {};
        data[field] = !result[field];

        this.setState({ altering: true });
        post(`/api/analytics/alter_scrub_row/${result.id}/`, data)
            .then(this.update_rows)
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));
    }

    mark_row_viewed(row) {
        if (isImpersonationActive()) {
            return;
        }
        if (!row || row.viewed) {
            return;
        }
        post(`/api/analytics/alter_scrub_row/${row.id}/`, { viewed: true })
            .then(this.update_rows)
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));
    }

    update_rows(value) {
        const new_output_rows = [...this.state.output_rows];
        // eslint-disable-next-line react/no-direct-mutation-state
        for (let index = 0; index < new_output_rows.length; index++) {
            if (new_output_rows[index]["id"] == value["id"]) {
                new_output_rows[index] = value;
                break;
            }
        }

        const filtered_rows = this.state.filtered_rows;
        filtered_rows[this.state.results_review_index] = value;

        this.setState({
            output_rows: new_output_rows,
            filtered_rows,
            altering: false,
        });
    }

    update_filtered_rows(state) {
        this.setState(
            state,
            function () {
                let total_bad_items = 0;
                const filtered_rows = [];
                for (const item of this.state.output_rows) {
                    const item_is_bad = this.is_parcel_bad(item);
                    if (item_is_bad !== false) {
                        total_bad_items += 1;
                    }

                    if (
                        this.state.current_filter == "bad_only" &&
                        item_is_bad == false
                    ) {
                        continue;
                    }
                    if (
                        this.state.current_filter == "good_only" &&
                        item_is_bad != false
                    ) {
                        continue;
                    }
                    if (
                        this.state.current_filter == "failure_only" &&
                        item.status !== LAND_SCRUBBING_ROW_FAILURE
                    ) {
                        continue;
                    }

                    filtered_rows.push(item);
                }

                this.setState({
                    filtered_rows,
                    total_bad_items,
                });
            }.bind(this),
        );
    }

    is_parcel_bad(row) {
        let is_bad = null;
        for (const key of [
            "land_locked",
            "wetlands",
            "flood_zone",
            "bad_topography",
            "odd_shape",
            "structure",
            "farmland",
        ]) {
            if (row[key] == null) {
                continue;
            }
            if (row[key]) {
                is_bad = true;
                break;
            } else {
                is_bad = false;
            }
        }
        return is_bad;
    }

    is_parcel_special(row) {
        return row.subdivide || row.entitlement;
    }

    move_to(index) {
        if (index < 0 || index >= this.state.filtered_rows.length) {
            return;
        }
        this.setState({ results_review_index: index });
        const current_row = this.state.filtered_rows[index];
        this.mark_row_viewed(current_row);
    }

    move_next() {
        this.move_to(this.state.results_review_index + 1);
    }

    move_prev() {
        this.move_to(this.state.results_review_index - 1);
    }

    highlight_result(e) {
        const new_index = this.state.filtered_rows.findIndex(
            (item) => item.data.id === e.data.id,
        );
        this.move_to(new_index);
    }

    render() {
        const { user } = this.context;

        if (!this.state.request) {
            return <Loading />;
        } else if (this.state.request.status !== "Completed") {
            return (
                <div className="card">
                    <div className="card-body">
                        <div
                            className="m-4 alert alert-info font-weight-bold text-white"
                            role="alert"
                        >
                            Processing... Check back in a few minutes.
                        </div>
                    </div>
                </div>
            );
        }

        const duplicates = this.state.request
            ? this.state.request.duplicates
                ? "Yes"
                : "No"
            : "";
        const land_locked = this.state.request
            ? this.state.request.land_locked
                ? "Yes"
                : "No"
            : "";
        const wetlands = this.state.request
            ? this.state.request.wetlands
                ? "Yes"
                : "No"
            : "";
        const flood_zone = this.state.request
            ? this.state.request.flood_zone
                ? "Yes"
                : "No"
            : "";

        const scrubbed_percent = Math.round(
            (parseFloat(this.state.total_bad_items) /
                parseFloat(this.state.output_rows.length)) *
                100,
        );

        const cost_saved = formatMoneyCents(MAILER_COST * this.state.total_bad_items);

        const columns = [
            {
                field: "row_number",
                headerName: "Row Number",
            },
            {
                field: "viewed",
                headerName: "Viewed",
                cellRenderer: ({ value }) =>
                    value ? <i className="fa fa-check" /> : "",
            },
        ];

        for (const item of this.state.output_headers) {
            columns.push({ field: item, filter: true });
        }
        columns.push({ field: "Bad Property", filter: true });

        if (this.state.show_result_column) {
            columns.push({
                field: "status",
                headerName: "Result",
                filter: true,
                valueFormatter: ({ value }) =>
                    value === LAND_SCRUBBING_ROW_FAILURE ? `Couldn't find match` : "",
            });
        }

        const ag_rows = [];
        for (const item of this.state.output_rows) {
            const row = item.data;

            // Copy model fields to raw data
            row.id = item.id;
            row.row_number = item.row_number;
            row.viewed = item.viewed;
            row.status = item.status;

            // AGGrid handles its own filtering
            const isBad = this.is_parcel_bad(item);
            row["Bad Property"] = isBad === null ? "Unknown" : isBad ? "Yes" : "No";

            ag_rows.push(row);
        }

        const filtered_rows = this.state.filtered_rows;

        let lat = null;
        let lng = null;
        let parcel_quality = null;
        let current_row = null;

        let alter_buttons;

        if (
            filtered_rows.length > 0 &&
            this.state.results_review_index < filtered_rows.length
        ) {
            current_row = filtered_rows[this.state.results_review_index];

            lat = current_row.lat || current_row["data"][this.state.request.lat];
            lng = current_row.lng || current_row["data"][this.state.request.lng];

            const is_bad = this.is_parcel_bad(current_row);
            const is_special = this.is_parcel_special(current_row);

            parcel_quality =
                is_bad == null
                    ? "unknown"
                    : is_bad
                      ? "bad"
                      : is_special
                        ? "highlight"
                        : "good";

            alter_buttons = (
                <>
                    <div className="d-flex justify-content-evenly flex-wrap g-4">
                        <AlterButton
                            label="Land Locked"
                            value={current_row.land_locked}
                            onClick={() => this.toggle_row("land_locked")}
                            disabled={this.state.altering}
                            hotkey="1"
                        />
                        <AlterButton
                            label="Wetlands"
                            value={current_row.wetlands}
                            onClick={() => this.toggle_row("wetlands")}
                            disabled={this.state.altering}
                            hotkey="2"
                        />
                        <AlterButton
                            label="Flood Zone"
                            value={current_row.flood_zone}
                            onClick={() => this.toggle_row("flood_zone")}
                            disabled={this.state.altering}
                            hotkey="3"
                        />
                        <AlterButton
                            label="Bad Topography"
                            value={current_row.bad_topography}
                            onClick={() => this.toggle_row("bad_topography")}
                            disabled={this.state.altering}
                            hotkey="4"
                        />
                        <AlterButton
                            label="Odd Shape"
                            value={current_row.odd_shape}
                            onClick={() => this.toggle_row("odd_shape")}
                            disabled={this.state.altering}
                            hotkey="5"
                        />
                        <AlterButton
                            label="Structure"
                            value={current_row.structure}
                            onClick={() => this.toggle_row("structure")}
                            disabled={this.state.altering}
                            hotkey="6"
                        />
                        <AlterButton
                            label="Farmland"
                            value={current_row.farmland}
                            onClick={() => this.toggle_row("farmland")}
                            disabled={this.state.altering}
                            hotkey="7"
                        />
                    </div>
                    <hr className="horizontal dark" />
                    <div className="d-flex justify-content-evenly flex-wrap g-4">
                        <AlterButton
                            label="Subdivide"
                            value={current_row.subdivide}
                            onClick={() => this.toggle_row("subdivide")}
                            disabled={this.state.altering}
                            special
                            hotkey="8"
                        />
                        <AlterButton
                            label="Entitlement"
                            value={current_row.entitlement}
                            onClick={() => this.toggle_row("entitlement")}
                            disabled={this.state.altering}
                            special
                            hotkey="9"
                        />
                    </div>
                </>
            );
        }

        let prev = null;
        if (this.state.results_review_index > 0) {
            prev = (
                <Button type="gradient-info" onClick={this.move_prev}>
                    Prev
                </Button>
            );
        }
        let next = null;
        if (this.state.results_review_index < filtered_rows.length - 1) {
            next = (
                <Button type="gradient-info" onClick={this.move_next}>
                    Next
                </Button>
            );
        }

        const getRowStyle = ({ data }) => {
            const { id, viewed, status } = data;

            // Set initial bg color to white
            let c = { h: 0, s: 0, l: 100 };

            if (status === LAND_SCRUBBING_ROW_FAILURE) {
                c = { h: 0, s: 100, l: 96 }; // Red
            } else if (viewed) {
                c = { h: 0, s: 0, l: 96 }; // Gray
            }

            // Darken current row
            if (current_row?.id === id) {
                c.l = 90;
            }

            return { background: `hsl(${c.h} ${c.s}% ${c.l}%)` };
        };

        return (
            <div style={{ textAlign: "center" }}>
                <div className="card mb-5">
                    <div className="card-header">
                        <h5>Scrubbing Results</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <h6>Settings</h6>
                                <div>{`Duplicates: ${duplicates}`}</div>
                                <div>{`Land Locked: ${land_locked}`}</div>
                                <div>{`Wetlands: ${wetlands}`}</div>
                                <div>{`Flood Zone: ${flood_zone}`}</div>
                            </div>

                            <div className="col-4">
                                <h6>Savings</h6>
                                <div>{`Scrubbed Percent: ${scrubbed_percent}%`}</div>
                                <div>{`Mailing Cost Reduction: ${cost_saved}`}</div>
                            </div>

                            <div className="col-4">
                                <h6>Download</h6>
                                <div>
                                    <Button
                                        type="gradient-primary"
                                        href={
                                            this.state.request
                                                ? `${API_URL}/api/analytics/download_scrub_results/${this.state.request.id}/`
                                                : null
                                        }
                                        target={"_blank"}
                                    >
                                        Only Clean Results
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="gradient-primary"
                                        href={
                                            this.state.request
                                                ? `${API_URL}/api/analytics/download_scrub_results/${this.state.request.id}/?all=true`
                                                : null
                                        }
                                        target={"_blank"}
                                    >
                                        Entire Dataset
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-5">
                    <div className="card-header pb-0">
                        <h5 className="mb-0">Parcel Review</h5>
                    </div>
                    <div className="card-body">
                        <div className="mb-3" style={{ position: "relative" }}>
                            <ParcelMap
                                lat={lat}
                                lng={lng}
                                parcel_quality={parcel_quality}
                                showInspectControl={user.is_staff}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "20px",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        padding: "6px 12px",
                                        background: "rgba(255, 255, 255, 0.92)",
                                        display: "inline-block",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                            marginRight: "20px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "25px",
                                                borderRadius: "10px",
                                                marginRight: "10px",
                                                verticalAlign: "top",
                                            }}
                                            src="/static/images/mapright/LightBlue.png"
                                        />
                                        <div
                                            style={{
                                                display: "inline-block",
                                            }}
                                        >
                                            Wetlands
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "inline-block",
                                            marginRight: "20px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "25px",
                                                borderRadius: "10px",
                                                marginRight: "10px",
                                                verticalAlign: "top",
                                            }}
                                            src="/static/images/mapright/DarkBlue.png"
                                        />
                                        <div
                                            style={{
                                                display: "inline-block",
                                            }}
                                        >
                                            Flood Zone
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "10px",
                                                marginRight: "10px",
                                                verticalAlign: "top",
                                                background: "white",
                                                border: "2px solid #ccc",
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                display: "inline-block",
                                            }}
                                        >
                                            Unknown
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h6>Scrub Reasons</h6>
                        <p className="text-xxs">Select All That Apply</p>
                        <Loading loaded={!this.state.altering} cover={true}>
                            {alter_buttons}
                        </Loading>

                        <hr className="horizontal dark" />

                        <h6 className="mb-3">{`Viewing Row ${
                            this.state.results_review_index + 1
                        } / ${filtered_rows.length}`}</h6>

                        <div className="row">
                            <div className="col-6" style={{ textAlign: "right" }}>
                                {prev}
                            </div>
                            <div className="col-6" style={{ textAlign: "left" }}>
                                {next}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-5">
                    <div className="card-header">
                        <h5>Results</h5>
                        <div className="card-body">
                            <div className="d-flex gap-2 justify-content-center">
                                <Button
                                    type={`gradient-info ${
                                        this.state.current_filter == "all"
                                            ? "disabled"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        this.update_filtered_rows({
                                            current_filter: "all",
                                            results_review_index: 0,
                                            saved_filters: {},
                                        })
                                    }
                                >
                                    All
                                </Button>
                                <Button
                                    type={`gradient-info ${
                                        this.state.current_filter == "bad_only"
                                            ? "disabled"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        this.update_filtered_rows({
                                            current_filter: "bad_only",
                                            results_review_index: 0,
                                            saved_filters: BAD_FILTERS,
                                        })
                                    }
                                >
                                    Only Bad Properties / Unknown
                                </Button>
                                <Button
                                    type={`gradient-info ${
                                        this.state.current_filter == "good_only"
                                            ? "disabled"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        this.update_filtered_rows({
                                            current_filter: "good_only",
                                            results_review_index: 0,
                                            saved_filters: {
                                                "Bad Property": {
                                                    filterType: "text",
                                                    type: "equals",
                                                    filter: "No",
                                                },
                                            },
                                        })
                                    }
                                >
                                    Only Good Properties
                                </Button>
                                {this.state.show_result_column ? (
                                    <Button
                                        type={`gradient-danger ${
                                            this.state.current_filter == "failure_only"
                                                ? "disabled"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            this.update_filtered_rows({
                                                current_filter: "failure_only",
                                                results_review_index: 0,
                                                saved_filters: {
                                                    status: {
                                                        filterType: "text",
                                                        type: "equals",
                                                        filter: "failure",
                                                    },
                                                },
                                            })
                                        }
                                    >
                                        Rows With Errors
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                        <AGGrid
                            rows={ag_rows}
                            columns={columns}
                            filters={this.state.saved_filters}
                            onCellClicked={this.highlight_result}
                            getRowStyle={getRowStyle}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withParams(LandScrubbingResult);
