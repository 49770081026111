import {
    type ForwardedRef,
    type ReactNode,
    type Ref,
    type RefAttributes,
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import { useVirtualizer } from "@tanstack/react-virtual";
import { useCombobox, type UseComboboxIsOpenChange } from "downshift";
import clsx from "clsx";
import { useResizeObserver } from "usehooks-ts";
import { useDebouncedCallback } from "use-debounce";
import { first, uniqueId } from "lodash";

import { Icon } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";

export interface AutoCompleteOption<T extends any> {
    id: string;
    label: string;
    // We use this value to display inside the autocomplete input after selection, its not required
    inputValue?: string;
    // For any additional data for easier access
    data?: T;
}
interface AutoCompleteProps<T> {
    id: string;
    setOptions: (options: AutoCompleteOption<T>[]) => void;
    options: AutoCompleteOption<T>[];
    searchValue: string;
    onChange: (inputValue: string) => void;
    selectedOption: AutoCompleteOption<T> | null;
    onSearch: (inputValue: string) => void;
    onSelect?: (selectedItem: AutoCompleteOption<T>) => void;
    placeholder?: string;
    hideBorder?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    renderLabel?: (item: AutoCompleteOption<T>) => ReactNode;
    onReset?: () => void;
    autoFocus?: boolean;
    label?: string;
    error?: React.ReactNode;
}

const AutocompleteComponent = <T,>(
    {
        id,
        options,
        onSearch,
        onSelect,
        placeholder = "Search",
        hideBorder = false,
        isLoading = false,
        renderLabel,
        disabled,
        searchValue,
        selectedOption,
        onChange,
        onReset,
        autoFocus,
        label,
        error,
    }: AutoCompleteProps<T>,
    ref: ForwardedRef<HTMLInputElement>,
) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0 } = useResizeObserver({
        ref: containerRef,
        box: "border-box",
    });
    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        reset,
    } = useCombobox({
        // inputValue: searchValue,
        onInputValueChange: ({ inputValue }) => {
            onSearch(inputValue);
        },
        items: options || [],
        onSelectedItemChange({ selectedItem }) {
            if (selectedItem) {
                onSelect(selectedItem);
                onChange(selectedItem.inputValue || selectedItem.label);
            } else {
                onSelect(null);
            }
        },
        itemToString(item) {
            return item ? item.label : "";
        },
        onIsOpenChange: (changes: UseComboboxIsOpenChange<AutoCompleteOption<T>>) => {
            const { isOpen } = changes;
            // Perform search to populate options when opened for the first time
            if (isOpen && searchValue.length === 0 && options.length === 0) {
                onSearch("");
            }
        },
        onHighlightedIndexChange: ({ highlightedIndex, type }) => {
            if (type !== useCombobox.stateChangeTypes.MenuMouseLeave) {
                rowVirtualizer.scrollToIndex(highlightedIndex);
            }
        },
    });

    const listRef = useRef();
    const rowVirtualizer = useVirtualizer({
        count: options.length,
        getScrollElement: () => listRef.current,
        estimateSize: () => 45,
        enabled: isOpen,
    });

    const items = rowVirtualizer.getVirtualItems();

    return (
        <div className="lui-w-full lui-relative lui-rounded-inherit">
            {label && (
                <label htmlFor={id} className="lui-m-0 lui-mb-1">
                    <Typography variant="span" weight="medium">
                        {label}
                    </Typography>
                </label>
            )}
            <div
                ref={containerRef}
                className={clsx(
                    " lui-w-full lui-h-full lui-overflow-hidden lui-m-0 lui-p-0",
                    {
                        "lui-border lui-border-solid lui-rounded-3xl": !hideBorder,
                        // Remove rounded bottom left corner when dropdown is open
                        "lui-rounded-b-none": isOpen && !hideBorder,
                        "lui-rounded-inherit": hideBorder,
                        "lui-border-red-700": error && !hideBorder && !isOpen,
                        "lui-border-gray-300": (!error && !hideBorder) || isOpen,
                    },
                )}
            >
                <div className="lui-flex lui-bg-white lui-items-center lui-justify-between lui-gap-1 lui-w-full lui-h-full">
                    <input
                        {...getInputProps({
                            ref,
                            // Custom onChange to prevent the cursor movement error https://github.com/downshift-js/downshift/issues/1108
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange(e.target.value);
                                onSearch(e.target.value);
                            },
                        })}
                        value={searchValue}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        className={clsx(
                            "lui-text-sm lui-font lui-flex-grow lui-font-inter lui-h-full lui-border-0 lui-px-3 lui-pl-4 focus-visible:lui-outline-none disabled:lui-cursor-not-allowed",
                            // py is 15.5px because the total of the input height should be 48px
                            "lui-py-[15.5px]",
                            {
                                "lui-font-medium lui-text-gray-1000":
                                    Boolean(selectedOption) || Boolean(searchValue),
                                "lui-font-normal lui-text-gray-700":
                                    !selectedOption && !searchValue,
                                "lui-rounded-inherit": hideBorder,
                            },
                        )}
                        // Prevent the browser from auto completing the input
                        autoComplete={uniqueId("autocomplete")}
                        disabled={disabled}
                    />

                    {Boolean(selectedOption) && (
                        <span
                            className="lui-text-gray-100 lui-cursor-pointer lui-px-3 lui-py-2.5 lui-flex lui-items-center lui-justify-center"
                            onClick={() => {
                                reset();
                                onReset();
                            }}
                        >
                            <Icon name="Close" size="sm" color="gray-600" />
                        </span>
                    )}
                </div>
                <div
                    className={clsx(
                        "lui-absolute lui-z-10 lui-bg-white lui-max-h-80 lui-overflow-auto lui-p-0 lui-py-2",
                        "lui-rounded-b-3xl",
                        "lui-border lui-border-solid",
                        {
                            "lui-hidden": !isOpen,
                            "lui-border-white": !isOpen,
                            "lui-border-solid lui-border-t lui-border-gray-200 ":
                                isOpen,

                            [`lui-autocomplete-${id}--open`]: isOpen, // This is used for to style parent component
                        },
                    )}
                    style={{
                        // Min height is needed when there are no options to show
                        minHeight: "55px",
                        // Adding 20px to prevent the scrollbar from appearing when the list of options is small
                        height: `${rowVirtualizer.getTotalSize() + 20}px`,
                        width: `${Math.floor(width)}px`,
                        overflowY: "auto",
                        contain: "strict",
                    }}
                    {...getMenuProps({ ref: listRef })}
                >
                    <div
                        style={{
                            height: rowVirtualizer.getTotalSize(),
                            position: "relative",
                        }}
                    >
                        <ul
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                transform: `translateY(${first(items)?.start ?? 0}px)`,
                            }}
                            className="lui-p-0 lui-m-0"
                        >
                            {isOpen && !isLoading && options.length === 0 && (
                                <li className="lui-py-2 lui-px-5 lui-flex lui-gap-4  ">
                                    <Typography variant="span">
                                        No results found
                                    </Typography>
                                </li>
                            )}

                            {isLoading && (
                                <li className="lui-py-2 lui-px-5 lui-flex lui-gap-4 lui-items-center">
                                    <Typography variant="span">Loading ...</Typography>
                                </li>
                            )}

                            {isOpen &&
                                !isLoading &&
                                items.map((virtualItem) => (
                                    <li
                                        key={virtualItem.key}
                                        data-index={virtualItem.index}
                                        className={clsx(
                                            "lui-items-center",
                                            "lui-autocomplete-popover",
                                            "lui-cursor-pointer",
                                            highlightedIndex === virtualItem.index &&
                                                "lui-bg-gray-100",
                                            selectedOption?.id ===
                                                options[virtualItem.index].id &&
                                                "lui-bg-primary-50 lui-font-bold",
                                            "lui-py-2.5 lui-px-4 lui-flex lui-gap-4",
                                        )}
                                        {...getItemProps({
                                            index: virtualItem.index,
                                            item: options[virtualItem.index],
                                            onClick: () => {
                                                // Fixes an issue where the selected item doesn't get selected again
                                                onSelect(options[virtualItem.index]);
                                            },
                                            ref: rowVirtualizer.measureElement,
                                        })}
                                    >
                                        {renderLabel &&
                                            renderLabel(options[virtualItem.index])}
                                        {!renderLabel && (
                                            <Typography variant="span" weight="medium">
                                                {options[virtualItem.index].label}
                                            </Typography>
                                        )}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            {error && (
                <span className="lui-mt-1 lui-flex lui-gap-1">
                    <Typography
                        size="sm"
                        variant="span"
                        color="red-700"
                        className="lui-flex lui-gap-1.5 lui-items-center"
                    >
                        <Icon name="Announcement" color="inherit" size="sm" />
                        {error}
                    </Typography>
                </span>
            )}
        </div>
    );
};

// Export the component with forwardRef
// This is to fix the generic type issue with forwardRef, see more here
// https://www.totaltypescript.com/forwardref-with-generic-components
function fixedForwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactNode,
): (props: P & RefAttributes<T>) => ReactNode {
    // @ts-ignore
    return forwardRef(render) as any;
}

export const Autocomplete = fixedForwardRef(AutocompleteComponent);

interface useAutocompleteProps<T>
    extends Omit<
        AutoCompleteProps<T>,
        "setOptions" | "options" | "searchValue" | "onChange" | "selectedOption"
    > {
    initialOptions?: AutoCompleteOption<T>[];
    onSearch: (inputValue: string) => Promise<AutoCompleteOption<T>[]>;
    onSelect?: (selectedItem: AutoCompleteOption<T>) => void;
    selectedOption?: AutoCompleteOption<T>;
    debounceDelay?: number;
}
interface useAutocompleteReturnProps<T> extends AutoCompleteProps<T> {
    ref: React.RefObject<HTMLInputElement>;
    clear: () => void;
}
export function useAutocomplete<T>({
    initialOptions,
    ...props
}: useAutocompleteProps<T>): useAutocompleteReturnProps<T> {
    const ref = useRef<HTMLInputElement>(null);
    const [id] = useState<string>(() => {
        return props.id || Math.random().toString(36).substring(7);
    });
    const [options, setOptions] = useState<AutoCompleteOption<T>[]>(
        initialOptions || [],
    );
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(props?.selectedOption?.label ?? "");

    const { onSearch: origOnSearch } = props;

    const _onSearch = useCallback(
        async (inputValue: string) => {
            setIsLoading(true);
            try {
                const newOptions = await origOnSearch(inputValue);
                setOptions(newOptions);
            } catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        },
        [origOnSearch],
    );

    const onSearch = useDebouncedCallback(_onSearch, props.debounceDelay ?? 500);

    const onSelect = (selectedItem: AutoCompleteOption<T>) => {
        if (props.onSelect) {
            props.onSelect(selectedItem);
        }
    };

    const onReset = () => {
        setOptions([]);
        setSearchValue("");
        if (Boolean(props.selectedOption)) {
            onSelect(null);
        }
        ref.current?.focus();
    };

    useEffect(() => {
        if (!props.selectedOption) {
            setSearchValue("");
        }
    }, [props.selectedOption]);

    return {
        ...props,
        id,
        ref,
        onChange: setSearchValue,
        searchValue,
        options,
        setOptions,
        selectedOption: props.selectedOption,
        onSelect,
        onSearch,
        isLoading,
        onReset,
        clear: () => {
            setTimeout(() => {
                setSearchValue("");
                onSearch("");
            }, 10);
        },
    };
}
