import clsx from "clsx";
import React, { useEffect } from "react";
import { type SVGCommand, SVGPathData } from "svg-pathdata";

enum HelperType {
    default = "default",
    implicit = "implicit",
    invisible = "invisible",
    invisibleFull = "invisibleFull",
    defaultChild = "defaultChild",
}

interface PathDataType {
    commands: SVGCommand[];
    bounds: {
        minX: number;
        maxX: number;
        minY: number;
        maxY: number;
    };
}

export function SVGViewer({ pathString }: { pathString: string }) {
    const [pathData, setPathData] = React.useState<PathDataType>({
        commands: [],
        bounds: { minX: 0, maxX: 0, minY: 0, maxY: 0 },
    });

    useEffect(() => {
        try {
            const data = new SVGPathData(pathString);
            setPathData({ commands: data.commands, bounds: data.getBounds() });
        } catch (error) {
            console.error("Error parsing pathString:", error);
            setPathData({
                commands: [],
                bounds: { minX: 0, maxX: 0, minY: 0, maxY: 0 },
            });
        }
    }, [pathString]);

    const stroke =
        Math.min(
            pathData.bounds.maxX - pathData.bounds.minX,
            pathData.bounds.maxY - pathData.bounds.minY,
        ) / 100;

    const style = React.useCallback(
        (type?: HelperType) => {
            return {
                style: {
                    color:
                        type === HelperType.default || type === HelperType.implicit
                            ? "lightgrey"
                            : undefined,
                    pointerEvents: (type === HelperType.invisibleFull ||
                    type === HelperType.invisible
                        ? "none"
                        : "initial") as "none" | "initial",
                },
                stroke: "currentColor",
                fill: "none",
                strokeDasharray:
                    type === HelperType.implicit || type === HelperType.invisible
                        ? stroke * 2
                        : "none",
                strokeWidth: stroke * 5,
            };
        },
        [stroke],
    );

    if (!pathData.commands.length) {
        return null;
    }

    const data = pathData.commands.reduce(
        (prev, c) => {
            switch (c.type) {
                case SVGPathData.MOVE_TO: {
                    const next = {
                        x: c.relative ? prev.current.x + c.x : c.x,
                        y: c.relative ? prev.current.y + c.y : c.y,
                    };

                    prev.start = next;
                    prev.current = next;
                    break;
                }
                case SVGPathData.LINE_TO: {
                    if (isNaN(prev.start.x)) {
                        prev.start = prev.current;
                    }
                    const next = {
                        x: c.relative ? prev.current.x + c.x : c.x,
                        y: c.relative ? prev.current.y + c.y : c.y,
                    };

                    prev.elems.push(
                        <line
                            key={`line-to-${prev.current.x}-${prev.current.y}-${next.x}-${next.y}`}
                            x1={prev.current.x}
                            y1={prev.current.y}
                            x2={next.x}
                            y2={next.y}
                            {...style()}
                        />,
                    );
                    prev.current = next;
                    break;
                }
                case SVGPathData.CLOSE_PATH: {
                    const next = prev.start;
                    prev.elems.push(
                        <line
                            key={`close-path-${prev.current.x}-${prev.current.y}-${next.x}-${next.y}`}
                            x1={prev.current.x}
                            y1={prev.current.y}
                            x2={next.x}
                            y2={next.y}
                            {...style()}
                        />,
                    );
                    prev.current = next;
                    prev.start = { x: NaN, y: NaN };
                    break;
                }

                default:
                    break;
            }
            return prev;
        },
        {
            elems: [] as React.ReactNode[],
            overlay: [] as React.ReactNode[],
            current: { x: 0, y: 0 },
            start: { x: NaN, y: NaN },
            previousCP: { x: NaN, y: NaN },
        },
    );

    const margin = stroke;

    const viewBox = [
        pathData.bounds.minX - margin,
        pathData.bounds.minY - margin,
        pathData.bounds.maxX - pathData.bounds.minX + margin * 2,
        pathData.bounds.maxY - pathData.bounds.minY + margin * 2,
    ];

    return (
        <svg
            viewBox={viewBox.join(" ")}
            style={{ backgroundImage: "url('/static/images/parcel_bg.png')" }}
            className={clsx(
                "lui-rounded-xl lui-text-[#FACC15]",
                "lui-p-2 lui-w-full lui-h-full",
            )}
        >
            {data.elems}
            {data.overlay}
        </svg>
    );
}
