import { fetchMapboxSuggestions } from "functions";
import { type Feature } from "geojson";
import { type MapRef, type LngLatLike } from "react-map-gl";
import type { Point } from "@src/pages/parcel_viewer/types";

// Maptiler filters
const ZIP_FILTER = [
    "all",
    ["==", ["get", "level"], 1],
    ["==", ["get", "iso_a2"], "US"],
];

interface FeatureTypeMapping {
    identifyLayer: string;
    idField: string[];
    filter?: any;
}

// Map featureType to Mapbox layers
const FEATURE_TYPE_MAPPING: Record<string, FeatureTypeMapping> = {
    district: {
        identifyLayer: "county_identify",
        idField: ["get", "fips"],
    },
    postcode: { identifyLayer: "zip_identify", filter: ZIP_FILTER, idField: ["id"] },
    // Parcel layer must use feature-state ID (properties) as a work workaround
    // because layer filters don't work with "promoteId" (Mapbox bug).
    // The "address" feature type mapping is used to locate parcel features for
    // both the Mapbox Address search and Parcel # search.
    address: { identifyLayer: "parcel_identify", idField: ["get", "id"] },
};

// Find Mapbox feature on map using administrative and parcel layers.
export async function findFeatureOnMap(
    map: MapRef,
    feature: Feature,
    paddingLeft: number = 40,
) {
    const { longitude, latitude } = feature.properties.coordinates;

    // County/Zip features return bbox. Address features return point.
    if (feature.properties.bbox) {
        map.fitBounds(feature.properties.bbox, {
            padding: {
                top: 100,
                bottom: 40,
                left: paddingLeft,
                right: 40,
            },
            // duration has a nonzero value to give tiles time to load before
            // calling queryRenderedFeatures. Otherwise, the projected point
            // may reference off-screen pixel values.
            duration: 250,
            // offset: [200, 30],
        });
    } else {
        (map as any).easeTo({ center: [longitude, latitude], zoom: 16, duration: 0 });
    }

    // Wait for map animation to stop, and for tiles to load, in order for
    // queryRenderedFeatures to work.
    await asyncMapEvent(map, "idle");

    const mapping: FeatureTypeMapping =
        FEATURE_TYPE_MAPPING[feature.properties.feature_type];
    if (!mapping) {
        console.log("feature type not supported", feature);
        return;
    }

    const { identifyLayer, filter, idField } = mapping;
    const point = map.project([longitude, latitude]);
    const features = map.queryRenderedFeatures(point, { layers: [identifyLayer] });

    const mapFeature = features && features[0];

    return { identifyLayer, filter, idField, mapFeature };
}

// Find parcel on map from its coordinates.
export async function findParcelOnMap(map: MapRef, point: Point) {
    (map as any).easeTo({ center: point, zoom: 16, duration: 0 });

    // Wait for map animation to stop, and for tiles to load, in order for
    // queryRenderedFeatures to work.
    await asyncMapEvent(map, "idle");

    // TS workaround to map Orval `readonly number[]` to react-map-gl `[number, number]`
    const lnglat = point as unknown as LngLatLike;

    const { identifyLayer, idField } = FEATURE_TYPE_MAPPING["address"];
    const screenPoint = map.project(lnglat);
    const features = map.queryRenderedFeatures(screenPoint, {
        layers: [identifyLayer],
    });

    const mapFeature = features && features[0];

    return { identifyLayer, idField, mapFeature };
}

export function parseCoordinates(coordinateString: string): Point {
    // Parse coordinates in order of more-to-less specific pattern matching
    return (
        parseDegreesMinutesSeconds(coordinateString) ||
        parseDecimalCoordinates(coordinateString)
    );
}

function parseDecimalCoordinates(coordinateString: string): Point {
    const [latStr, lngStr] = coordinateString.split(",").map((str) => str.trim());
    const lat = parseFloat(latStr);
    const lng = parseFloat(lngStr);
    const point: Point = [lng, lat];
    return !isNaN(lat) && !isNaN(lng) ? point : null;
}

function parseDegreesMinutesSeconds(coordinateString: string): Point {
    function parseSingleCoordinate(coord: string): number {
        if (!coord) return null;
        const regex = /(\d+)° (\d+)' (\d+\.\d+)" ([NSEW])/;
        const match = regex.exec(coord);
        if (!match) return null;

        const [, degrees, minutes, seconds, direction] = match;
        let decimalDegrees =
            parseInt(degrees) + parseInt(minutes) / 60 + parseFloat(seconds) / 3600;

        if (direction === "S" || direction === "W") {
            decimalDegrees = -decimalDegrees;
        }

        return isNaN(decimalDegrees) ? null : decimalDegrees;
    }

    const [latStr, lngStr] = coordinateString.split(", ");
    const lat = parseSingleCoordinate(latStr);
    const lng = parseSingleCoordinate(lngStr);
    const point: Point = [lng, lat];

    return lat !== null && lng !== null ? point : null;
}

export function formatCoordinates(point: Point) {
    function format(coord: number, type: "lat" | "lng") {
        const absolute = Math.abs(coord);
        const degrees = Math.floor(absolute);
        const minutes = Math.floor((absolute - degrees) * 60);
        const seconds = ((absolute - degrees) * 60 - minutes) * 60;
        const direction =
            coord >= 0 ? (type === "lat" ? "N" : "E") : type === "lat" ? "S" : "W";
        return `${degrees}° ${minutes}' ${seconds.toFixed(2)}" ${direction}`;
    }
    const [lng, lat] = point;
    return `${format(lat, "lat")}, ${format(lng, "lng")}`;
}

export async function searchMapbox(query: string, featureType: string) {
    try {
        const data = await fetchMapboxSuggestions(query, {
            types: featureType,
        });
        return data?.suggestions || [];
    } catch (xhr) {
        console.log(xhr);
    }
}

// Wrap map event handler in promise to use with async/await.
function asyncMapEvent(map: MapRef, eventName: string): Promise<void> {
    return new Promise<void>((resolve) => {
        map.once(eventName, () => resolve());
    });
}
