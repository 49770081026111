import { Layer, Source } from "react-map-gl";

export function ContourLayer() {
    return (
        <Source
            id="terrain-source"
            type="vector"
            url="mapbox://mapbox.mapbox-terrain-v2"
        >
            <Layer
                id="contours"
                type="line"
                source="terrain-source"
                source-layer="contour"
                layout={{
                    "line-join": "round",
                    "line-cap": "round",
                    visibility: "visible",
                }}
                paint={{
                    "line-color": "#FAE4AA",
                    "line-width": 2,
                }}
            />

            <Layer
                id="contour-labels"
                type="symbol"
                source="terrain-source"
                source-layer="contour"
                layout={{
                    "symbol-placement": "line",
                    "text-field": [
                        "concat",
                        [
                            "to-string",
                            ["round", ["*", ["coalesce", ["get", "ele"], 0], 3.28084]],
                        ],
                        " ft",
                    ],
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    "text-size": 14,
                    "text-letter-spacing": 0.1,
                    "text-line-height": 1.6,
                    "text-max-angle": 30,
                    "text-ignore-placement": true,
                    "text-anchor": "center",
                }}
                paint={{
                    "text-halo-width": 2,
                    "text-halo-color": "#333",
                    "text-color": "#F2A441",
                }}
            />
        </Source>
    );
}
