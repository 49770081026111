import { isImpersonationActive, clearImpersonationToken } from "functions";
import { ErrorBoundary } from "@src/land_ui/error_boundary/error_boundary";

export default function BaseLayout(props) {
    const exit = () => {
        clearImpersonationToken();
        window.location.href = "/home/admin";
    };

    return (
        <ErrorBoundary>
            <div {...props}>
                {isImpersonationActive() && (
                    <div className="alert alert-secondary font-weight-bold text-center">
                        <button className="btn btn-primary mb-0" onClick={exit}>
                            Exit Impersonation Mode
                        </button>
                    </div>
                )}
                {props.children}
            </div>
        </ErrorBoundary>
    );
}
